import React from 'react';
import { FormTextField } from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';

export function UFormGovAgendaFields() {
  return (
    <>
      <FormTextField
        name="externalId"
        label={<HelpLabel label="Externí ID" code="DICT_AGENDA_EXTERNAL_ID" />}
      />
      <FormTextField
        name="description"
        label={<HelpLabel label="Popis" code="DICT_AGENDA_DESCRIPTION" />}
      />
    </>
  );
}
