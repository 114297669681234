import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { Title } from '../../components/title/title';
import { ProtectedPage } from '../../components/protected-page/protected-page';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useFetch, FilesContext } from '@eas/common-web';
import { EvidenceAPI } from '../../enums';
import { UForm } from '../../models';
import CircularProgress from '@material-ui/core/CircularProgress';

const messages = {
  OK: 'Autorizace proběhla úspěšně',
  EXCEEDED: 'Překročen maximální počet pokusů',
  CANCELED: 'Autorizace byla zrušena',
  FAILED: 'Autorizace selhala',
};

export const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    gap: '20px',
    marginTop: 20,
  },
  button: {
    minWidth: 300,
    whiteSpace: 'nowrap',
  },
  delimeter: {
    marginTop: 20,
  },
  loader: {
    marginTop: 10,
  },
});

export function UFormResult() {
  const { search } = useLocation();
  const { getFileUrl } = useContext(FilesContext);

  const classes = useStyles();

  const params = new URLSearchParams(search);

  const result = (params.get('result') || 'FAILED') as keyof typeof messages;
  const uformId = params.get('uformId');

  const [uForm, loading] = useFetch<UForm>(`${EvidenceAPI.UFORM}/${uformId}`, {
    method: 'GET',
    skip: !uformId,
  });

  const message = messages[result] || messages.FAILED;

  return (
    <ProtectedPage>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Title name="Výsledek autorizace" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              <ul>
                <li>
                  Výsledek:{' '}
                  <b>
                    {result}, {message}
                  </b>
                </li>
                {!loading && uForm ? (
                  <>
                    <li>
                      Čas úkonu:{' '}
                      <b>
                        {uForm.authorization?.time
                          ? new Date(uForm.authorization?.time).toLocaleString(
                              'cs-CZ'
                            )
                          : ''}
                      </b>
                    </li>
                    <li>
                      ID úkonu: <b>{uForm.authorization?.id}</b>
                    </li>
                  </>
                ) : (
                  <CircularProgress
                    size="18px"
                    disableShrink
                    className={classes.loader}
                  />
                )}
              </ul>
            </Typography>

            {result === 'OK' && (
              <>
                <Typography variant="body1" className={classes.delimeter}>
                  Autorizace proběhla úspěšně. Nyní můžete pokračovat v dalších
                  krocích.
                </Typography>

                <div className={classes.buttonGroup}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disableElevation
                  >
                    Odeslat na MŽP
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disableElevation
                  >
                    Vytvořit koncept v DS
                  </Button>

                  {uForm?.pdfFile?.id && (
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      disableElevation
                      download={true}
                      href={getFileUrl(uForm.pdfFile.id)}
                    >
                      Stáhnout PDF
                    </Button>
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </ProtectedPage>
  );
}
