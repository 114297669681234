export enum EntityName {
  // main entities
  AGENDA = 'Agenda',
  ALOG = 'Event',
  BOILER_MANUFACTURER = 'BoilerManufacturer',
  BOILER_PERMISSION = 'BoilerPermission',
  DIRP_RECORD = 'DirpRecord',
  EMPOWERMENT = 'Empowerment',
  EMPOWERMENT_REQUEST = 'EmpowermentRequest',
  IRZ_FACILITY = 'IrzFacility',
  IRZ_FACILITY_REQUEST = 'IrzFacilityRequest',
  ISPOP_DOCUMENT = 'IspopDocument',
  MAIL_CAMPAIGN = 'MailCampaign',
  NOTIFICATION_BATCH = 'CrzpNotificationBatch',
  NOTIFICATION_TEMPLATE = 'NotificationTemplate',
  OUTAGE = 'Outage',
  OVM = 'Ovm',
  OVZ_FACILITY = 'OvzFacility',
  OVZ_FACILITY_REQUEST = 'OvzFacilityRequest',
  PERSONAL_DATA = 'PersonalData',
  PRODUCTION_SITE = 'ProductionSite',
  PROTECTED_DEPOSIT_AREA = 'ProtectedDepositArea',
  PUBLIC_LIST = 'PublicList',
  ROLE_INSTANCE = 'RoleInstance',
  ROLE_REQUEST = 'RoleRequest',
  ROLE = 'Role',
  SCHEDULE_JOB = 'Job',
  SCHEDULE_RUN = 'Run',
  SOAP_EXECUTION_LOG = 'SoapExecutionLog',
  SOAP_MESSAGE = 'SoapMessage',
  SOAP_MESSAGE_LOG = 'SoapMessageLog',
  SUBJECT = 'Subject',
  SUBJECT_REQUEST = 'SubjectRegistration',
  SYSTEM = 'System',
  THIRD_PARTY_SYSTEM = 'ThirdPartySystem',
  TRANSLATION = 'Translation',
  USER = 'User',
  WATER_MANAGEMENT_DECISION = 'WaterManagementDecision',
  WATER_MANAGEMENT_DECISION_PART = 'WaterManagementDecisionPart',
  WATER_USAGE_PLACE = 'WaterUsagePlace',

  // dictionaries
  BALANCE_UNIT = 'BalanceUnit',
  BOILER_BRAND = 'BoilerBrand',
  BOILER_MODEL = 'BoilerModel',
  BOILER_TYPE = 'BoilerType',
  COMPETENT_AUTHORITY = 'CompetentAuthority',
  COMPETENT_CONTROL_AUTHORITY = 'CompetentControlAuthority',
  COUNTRY = 'Country',
  CZ_RIVER_BASIN_DISTRICT = 'CzRiverBasinDistrict',
  DECISION_TYPE = 'DecisionType',
  DEPOSIT = 'Deposit',
  DIRP_AREA = 'DirpArea',
  DIRP_AUTHOR = 'DirpAuthor',
  DIRP_LAW = 'Law',
  DIRP_LAW_PARAGRAPH = 'LawParagraph',
  DIRP_TYPE = 'DirpType',
  E_PRTR_ACTIVITY = 'EprtrActivity',
  EU_RIVER_BASIN_DISTRICT = 'EuRiverBasinDistrict',
  EXPORT_TEMPLATE = 'ExportTemplate',
  HYDROGEOLOGICAL_ZONE = 'HydrogeologicalZone',
  INDICATOR = 'Indicator',
  INFO = 'DictionaryInfo',
  IPPC_ACTIVITY = 'IppcActivity',
  IRZ_ACTIVITY = 'IrzActivity',
  LEGAL_FORM = 'LegalForm',
  MINERAL_GROUP = 'MineralGroup',
  CZ_NACE = 'CzNace',
  NACE = 'Nace',
  OVSS = 'Ovss',
  PROCESSING_PURPOSE = 'ProcessingPurpose',
  RIVER_SIDE = 'RiverSide',
  RUIAN_ADDRESS_PLACE = 'AddressPlace',
  RUIAN_CADASTRAL_TERRITORY = 'CadastralTerritory',
  RUIAN_CITY_DISTRICT = 'CityDistrict',
  RUAIN_CITY_DISTRICT_PRAGUE = 'CityDistrictPrague',
  RUIAN_COHESION_REGION = 'CohesionRegion',
  RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY = 'CommissionedLocalAuthorityMunicipality',
  RUIAN_DISTRICT = 'District',
  RUIAN_ADMINISTRATIVE_DISTRICT_PRAGUE = 'AdministrativeDistrictPrague',
  RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY = 'ExtendedCompetenceMunicipality',
  RUIAN_MUNICIPALITY = 'Municipality',
  RUIAN_MUNICIPALITY_PART = 'MunicipalityPart',
  RUIAN_REGION = 'Region',
  RUIAN_STREET = 'Street',
  SAMPLE_TYPE = 'SampleType',
  SEQUENCE = 'Sequence',
  SEWER_TYPE = 'SewerType',
  TERRITORIAL_TECHNICAL_UNIT = 'TerritorialTechnicalUnit',
  WATER_FLOW = 'WaterFlow',
  WATER_ORIGIN = 'WaterOrigin',
  WATER_TREATMENT_METHOD = 'WaterTreatmentMethod',
}
