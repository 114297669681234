import React from 'react';
import { StepAgendaForm } from './step-agenda-types';

export function StepAgendaLabel({
  stepValues,
}: {
  stepValues?: StepAgendaForm;
}) {
  return (
    <>
      Základní údaje: {stepValues?.act?.name && <b>{stepValues?.act?.name}</b>}
    </>
  );
}
