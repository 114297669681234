import React, { useContext } from 'react';
import {
  FormPanel,
  FormTextField,
  FormCheckbox,
  FormFileField,
  FormSelect,
  useFormSelector,
  useStaticListSource,
  FormCustomField,
  FormContext,
  SnackbarContext,
  SnackbarVariant,
  FormDateField,
} from '@eas/common-web';
import { HelpLabel } from '../../../components/help/help-label';
import { AddressField } from '../../../components/form/address-field/address-field';
import { StepOriginatorForm } from './step-originator-types';
import { PersonType } from '../../../models/uform';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { getSubjectByIco } from '../../subject/subjects-api';

export const useStyles = makeStyles(() => ({
  actionButtonGroup: {
    width: 100,
  },
  actionButton: {
    width: 100,
    height: 22,
    padding: '0 10px',
    marginLeft: 3,
    fontSize: '12px',
    fontWeight: 600,
    flexShrink: 0,
  },
}));

export function StepOriginatorContent() {
  const {
    actorProxy,
    representedPersonType,
    differentContactAddress,
    representedPersonIco,
  } = useFormSelector((values: StepOriginatorForm) => ({
    actorProxy: values.actor?.proxy,
    representedPersonType: values.representedPerson?.type,
    differentContactAddress: values?.representedPerson?.differentContactAddress,
    representedPersonIco: values.representedPerson?.ico,
  }));

  const classes = useStyles();

  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext<FormContext<StepOriginatorForm>>(
    FormContext
  );

  const representedPersonOptions = useStaticListSource([
    { id: PersonType.NATURAL_PERSON, name: 'Fyzická osoba' },
    { id: PersonType.BUSINESS_PERSON, name: 'Osoba s IČO' },
    { id: PersonType.FOREIGN_PERSON, name: 'Zahraniční osoba' },
  ]);

  return (
    <>
      <FormPanel label="Osoba činící úkon">
        <FormTextField
          name="actor.firstName"
          label={
            <HelpLabel label="Jméno" code="UFORM_FIRST_NAME" required={true} />
          }
          disabled
        />
        <FormTextField
          name="actor.lastName"
          label={
            <HelpLabel
              label="Příjmení"
              code="UFORM_LAST_NAME"
              required={true}
            />
          }
          disabled
        />
        <FormDateField
          name="actor.birthDate"
          label={<HelpLabel label="Datum narození" code="UFORM_BIRTH_DATE" />}
        />
        <FormTextField
          name="actor.idNumber"
          label={<HelpLabel label="Číslo OP" code="UFORM_BIRTH_ID_NUMBER" />}
        />
        <FormTextField
          name="actor.databox"
          label={
            <HelpLabel label="Datová schránka" code="UFORM_BIRTH_DATABOX" />
          }
        />
        <FormSelect
          name="actor.preferredResponseChannel"
          label={
            <HelpLabel
              label="Preferovaný kanál pro odpověď "
              code="UFORM_PREFERRED_RESPONSE_CHANNEL"
            />
          }
          valueIsId={true}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          source={useStaticListSource([
            { id: 'ISDS', name: 'Datová schránka' },
            { id: 'MAIL', name: 'Pošta' },
          ])}
        />
        <FormTextField
          name="actor.email"
          label={<HelpLabel label="E-mail" code="UFORM_EMAIL" />}
        />
        <FormTextField
          name="actor.phone"
          label={<HelpLabel label="Telefonní číslo" code="UFORM_PHONE" />}
        />
        <AddressField name="actor.address" disableForeignCountry={true} />
        <FormCheckbox
          name="actor.proxy"
          label={
            <HelpLabel
              label="Úkon prováděn v zastoupení"
              code="UFORM_ACTING_ON_BEhalf"
            />
          }
          notifyChange={() => {
            setFieldValue('actor.powerOfAttorney', null);
            setFieldValue('representedPerson', null);
          }}
        />
        {actorProxy && (
          <>
            <FormFileField
              name="actor.powerOfAttorney"
              label={
                <HelpLabel
                  label="Plná moc"
                  code="UFORM_POWER_OF_ATTORNEY"
                  required
                />
              }
              accept={['.pdf']}
            />
            <FormSelect
              name="representedPerson.type"
              label={
                actorProxy ? (
                  <HelpLabel
                    label="Podání je činěno za"
                    code="UFORM_SUBMISSION_FOR"
                    required
                  />
                ) : (
                  <HelpLabel
                    label="Podání činí"
                    code="UFORM_SUBMISSION_FOR"
                    required
                  />
                )
              }
              valueIsId={true}
              source={representedPersonOptions}
            />
          </>
        )}
      </FormPanel>

      {representedPersonType === PersonType.NATURAL_PERSON && (
        <>
          <FormPanel label="Fyzická osoba">
            <FormTextField
              name="representedPerson.firstName"
              label={
                <HelpLabel
                  label="Jméno"
                  code="UFORM_FIRST_NAME"
                  required={true}
                />
              }
            />
            <FormTextField
              name="representedPerson.lastName"
              label={
                <HelpLabel
                  label="Příjmení"
                  code="UFORM_LAST_NAME"
                  required={true}
                />
              }
            />
            <FormDateField
              name="representedPerson.birthDate"
              label={
                <HelpLabel label="Datum narození" code="UFORM_BIRTH_DATE" />
              }
            />
            <FormTextField
              name="representedPerson.idNumber"
              label={
                <HelpLabel label="Číslo OP" code="UFORM_INDIVIDUAL_ID_NUMBER" />
              }
            />
            <AddressField
              name="representedPerson.address"
              disableForeignCountry={true}
            />
            <FormCheckbox
              name="representedPerson.differentContactAddress"
              label={
                <HelpLabel
                  label="Kontaktní adresa je jiná než adresa trvalého pobytu"
                  code="UFORM_DIFFERENT_CONTACT_ADDRESS"
                />
              }
              notifyChange={() => {
                setFieldValue('representedPerson.contactAddress', null);
              }}
            />
          </FormPanel>
          {differentContactAddress && (
            <FormPanel label="Kontaktní adresa">
              <AddressField
                name="representedPerson.contactAddress"
                disableForeignCountry={true}
              />
            </FormPanel>
          )}
        </>
      )}

      {representedPersonType === PersonType.BUSINESS_PERSON && (
        <FormPanel label="Osoba s IČO">
          <FormCustomField
            name="representedPerson.icoWrapper"
            label={<HelpLabel label="IČO" code="UFORM_ICO" required={true} />}
          >
            <FormTextField
              name="representedPerson.ico"
              labelOptions={{
                hide: true,
              }}
              layoutOptions={{
                noSpacing: true,
                noUnderline: true,
              }}
            />
            <Button
              onClick={() => {
                getSubjectByIco(representedPersonIco!)
                  .json()
                  .then((acts) => {
                    if (acts?.items?.length === 1) {
                      showSnackbar('Subjekt nalezen', SnackbarVariant.SUCCESS);

                      setFieldValue(
                        'representedPerson.companyName',
                        acts.items[0].name
                      );
                      setFieldValue(
                        'representedPerson.address',
                        acts.items[0].residence
                      );
                    } else {
                      showSnackbar(
                        'Subjekt se nepodařilo najít',
                        SnackbarVariant.ERROR
                      );
                    }
                  });
              }}
              disabled={!representedPersonIco}
              variant="text"
              startIcon={<SearchIcon />}
              className={classes.actionButton}
            >
              Hledat
            </Button>
          </FormCustomField>
          <FormTextField
            name="representedPerson.companyName"
            label={
              <HelpLabel
                label="Název"
                code="UFORM_COMPANY_NAME"
                required={true}
              />
            }
          />
          <FormTextField
            name="representedPerson.databox"
            label={<HelpLabel label="Datová schránka" code="UFORM_DATA_BOX" />}
          />
          <AddressField
            name="representedPerson.contactAddress"
            disableForeignCountry={true}
          />
        </FormPanel>
      )}

      {representedPersonType === PersonType.FOREIGN_PERSON && (
        <>
          <FormPanel label="Zahraniční osoba">
            <FormTextField
              name="representedPerson.ico"
              label={
                <HelpLabel
                  label="Zahraniční identifikátor (obdoba IČO)"
                  code="UFORM_FOREIGN_IDENTIFIER"
                  required={true}
                />
              }
            />
            <FormTextField
              name="representedPerson.companyName"
              label={
                <HelpLabel
                  label="Název"
                  code="UFORM_FOREIGN_COMPANY_NAME"
                  required={true}
                />
              }
            />
            <AddressField
              name="representedPerson.address"
              disableForeignCountry={false}
            />
          </FormPanel>

          <FormPanel label="Kontaktní adresa na území ČR">
            <AddressField
              name="representedPerson.contactAddress"
              disableForeignCountry={true}
            />
          </FormPanel>
        </>
      )}
    </>
  );
}
