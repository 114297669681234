import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { UForm } from '../../models';
import { useUFormGovAgendaAutocomplete } from '../uform-gov-agenda/uform-gov-agenda-api';
import { useUFormGovServiceAutocomplete } from '../uform-gov-service/uform-gov-service-api';

export function useColumns(): TableColumn<UForm>[] {
  return [
    {
      datakey: 'name',
      name: 'Název',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'act.service.agenda',
      filterkey: 'agenda.id',
      sortkey: 'agenda.name',
      name: 'Agenda',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUFormGovAgendaAutocomplete
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'act.service',
      filterkey: 'service.id',
      sortkey: 'service.name',
      name: 'Služba',
      width: 200,
      filterOperation: ApiFilterOperation.EQ,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useUFormGovServiceAutocomplete
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
  ];
}
