import { abortableFetch, DetailHandle, DetailContext } from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';
import { useContext } from 'react';
import { UFormGovAct } from '../../../models';

export function useEditHelpDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<UFormGovAct>>(DetailContext);
  const callApi = (id: string, values: { text: string }) => {
    return abortableFetch(`${EvidenceAPI.UFORM_GOV_ACT}/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        help: values.text,
      }),
    });
  };

  const canEditHelp = true;

  return { callApi, canEditHelp };
}
