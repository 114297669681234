import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { ProcessForm } from '../uform-types';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { EvidenceAPI } from '../../../enums';
import { FilesContext } from '@eas/common-web';

export const useStyles = makeStyles({
  button: {
    minWidth: 300,
    whiteSpace: 'nowrap',
  },
  section: {
    display: 'flex',
    gap: '20px',
    marginTop: 20,
    alignItems: 'center',
  },
  delimeter: {
    marginTop: 20,
  },
  bold: { marginTop: 30, fontWeight: 800 },
  italic: { marginTop: 30, fontStyle: 'italic' },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface Props {
  allValues: ProcessForm;
}

export function StepFinishContent({ allValues }: Props) {
  const classes = useStyles();
  // const { user } = useContext(UserContext);

  const Delim = () => (
    <Typography variant="body1" className={classes.delimeter}>
      nebo
    </Typography>
  );

  const isMZP = allValues.recipient?.authority?.code === '00164801';

  return (
    <div className={classes.wrapper}>
      <>
        <Typography variant="body1">
          Formulář ID XXXXXX je připraven ve formátu PDF k dalšímu zpracování.
          Nyní můžete:
        </Typography>

        {isMZP && (
          <>
            <AuthorizeAndSendSection allValues={allValues} />
            <Delim />
          </>
        )}

        {!isMZP && (
          <>
            <AuthorizeAndSendToDataBoxSection allValues={allValues} />
            <Delim />
            <AuthorizeAndDownloadSection allValues={allValues} />
            <Delim />
            <DownloadSection allValues={allValues} />
          </>
        )}
      </>
    </div>
  );
}

function useRedirectLink(uFormId?: string) {
  let redirectUrl = 'https://t-iam.env.cz/cas/authorize';

  const service =
    process.env.NODE_ENV === 'development'
      ? `http://localhost:8090${EvidenceAPI.UFORM}/${uFormId}/authorization`
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ENVIRONMENT === 'dev'
      ? `https://crzp-dev.mzp.cz${EvidenceAPI.UFORM}/${uFormId}/authorization`
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ENVIRONMENT === 'test'
      ? `https://crzp-test.mzp.cz${EvidenceAPI.UFORM}/${uFormId}/authorization`
      : // production
        `https://crzp.mzp.cz${EvidenceAPI.UFORM}/${uFormId}/authorization`;

  const nazev = 'dokument.pdf';
  const popis = 'autorizace dokumentu dokument.pdf';
  const hash = '123456abcd';

  const searchParams = new URLSearchParams({
    service,
    nazev,
    popis,
    hash,
  });

  redirectUrl += '?' + searchParams.toString();

  return redirectUrl;
}

function AuthorizeAndSendSection({ allValues }: Props) {
  const classes = useStyles();

  const redirectUrl = useRedirectLink(allValues.request?.id);

  return (
    <div className={classes.section}>
      <div>
        <Typography variant="body1" className={classes.bold}>
          1) Odeslat autorizovaný formulář na Ministerstvo životního prostředí
        </Typography>
        <Typography variant="body1">
          Budete vyzváni k autorizaci formuláře (obdoba digitálního podpisu) a
          následně bude formulář odeslán přímo na Ministerstvo životního
          prostředí k vyřízení. Záznam o vytvoření, autorizaci a provedení
          podání formuláře (metadata) včetně pdf dokumentu formuláře, jeho
          příloh a osvědčení o provedeném úkonu dle zákona č. 12/2020 Sb. Vám
          bude dostupný v záložce Jednoduchý formulář -&gt; Moje podání.
        </Typography>
      </div>

      <Button
        variant="contained"
        color="default"
        className={classes.button}
        disableElevation
        href={redirectUrl}
      >
        Autorizovat a Odeslat
      </Button>
    </div>
  );
}

function AuthorizeAndSendToDataBoxSection({ allValues }: Props) {
  const classes = useStyles();

  const redirectUrl = useRedirectLink(allValues.request?.id);

  return (
    <div className={classes.section}>
      <div>
        <Typography variant="body1" className={classes.bold}>
          1) Odeslat autorizovaný formulář přes vlastní datovou schránku do
          datové schránky příslušného úřadu uvedeného ve formuláři
        </Typography>
        <Typography variant="body1">
          Budete vyzváni k autorizaci formuláře (obdoba digitálního podpisu) a
          následně vyzváni k přihlášení do své datové schránky. V ní bude
          vytvořen koncept datové zprávy (obsahují autorizovaný formulář podání
          a nahrané přílohy), kterou budete moci prostřednictvím Vámi zvolené
          datové schránky zaslat na příslušný úřadu uvedeného ve formuláři.
        </Typography>
        <Typography variant="body1" className={classes.italic}>
          Záznam o vytvoření a autorizaci formuláře (metadata) {'<?'} včetně pdf
          dokumentu formuláře a jeho příloh{'>'} Vám bude dostupný v záložce
          Jednoduchý formulář -&gt; Moje podání.
        </Typography>
      </div>

      <Button
        variant="contained"
        color="default"
        className={classes.button}
        disableElevation
        href={redirectUrl}
      >
        Autorizovat a Odeslat
      </Button>
    </div>
  );
}

function AuthorizeAndDownloadSection({ allValues }: Props) {
  const classes = useStyles();

  const redirectUrl = useRedirectLink(allValues.request?.id);

  return (
    <div className={classes.section}>
      <div>
        <Typography variant="body1" className={classes.bold}>
          2) Autorizovat formulář a stáhnout PDF
        </Typography>
        <Typography variant="body1">
          Budete vyzváni k autorizaci formuláře (obdoba digitálního podpisu) a
          následně můžete autorizovaný formulář stáhnout a uložit pro další
          použití. Přílohy nejsou součástí staženého PDF. V případě zájmu
          odeslat na příslušný úřad (např. na e-podatelnu zvoleného úřadu ve
          formuláři) prostřednictvím el. podepsaného emailu, nezapomeňte vložit
          do zprávy příslušné přílohy, které jste nahráli do systému v rámci
          vyplňování formuláře.
        </Typography>
        <Typography variant="body1" className={classes.italic}>
          Záznam o vytvoření a autorizaci formuláře (metadata) {'<?'} včetně pdf
          dokumentu formuláře a jeho příloh{'>'} Vám bude dostupný v záložce
          Jednoduchý formulář -&gt; Moje podání.
        </Typography>
      </div>

      <Button
        variant="contained"
        color="default"
        className={classes.button}
        disableElevation
        href={redirectUrl}
      >
        Autorizovat a stáhnout
      </Button>
    </div>
  );
}

function DownloadSection({ allValues }: Props) {
  const classes = useStyles();
  const { getFileUrl } = useContext(FilesContext);

  return (
    <div className={classes.section}>
      <div>
        <Typography variant="body1" className={classes.bold}>
          3) Stáhnout PDF bez autorizace
        </Typography>
        <Typography variant="body1">
          Formulář bude uložen do Vašeho zařízení bez autorizace. Můžete jej
          následně předat na příslušný úřad později jiným způsobem (osobně nebo
          přes jinou službu). Tuto volbu zvolte, pokud nechcete mít uložené
          podání v systému a chcete např. formulář a přílohy vytisknout a před
          na úřad osobně v tištěné podobě.
        </Typography>
      </div>

      <Button
        variant="contained"
        color="default"
        className={classes.button}
        disableElevation
        download={true}
        href={getFileUrl(allValues.request?.pdfFile?.id ?? '')}
      >
        Stáhnout
      </Button>
    </div>
  );
}
