import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  editor: {
    fontSize: 14,
    fontWeight: 500,
    // backgroundColor: 'white',
    width: '100%',
    padding: '0 6px',
    '& *': {
      lineHeight: '2em',
      minHeight: '2em',
    },
    '& ul, & ol': {
      marginLeft: '20px',
    },
  },
  active: {
    backgroundColor: theme.palette.editing,
  },
  helpPanel: {
    width: '100%',
    marginTop: '10px !important',
  },
}));
