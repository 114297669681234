import React from 'react';
import {
  FormAutocomplete,
  FormTextArea,
  FormRichEditor,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useUFormGovServiceAutocomplete } from '../uform-gov-service/uform-gov-service-api';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';

export function UFormGovActFields() {
  const services = useUFormGovServiceAutocomplete();

  const phaseOptions = useStaticListSource([
    {
      id: 'START',
      name: 'Začátek',
    },
    {
      id: 'MIDDLE',
      name: 'Prostředek',
    },
    {
      id: 'END',
      name: 'Konec',
    },
  ]);

  return (
    <>
      <FormTextArea
        name="description"
        label={<HelpLabel label="Popis" code="DICT_ACT_DESCRIPTION" />}
      />
      <FormRichEditor
        name="help"
        label={<HelpLabel label="Nápověda" code="DICT_ACT_HELP" />}
      />
      <FormAutocomplete
        name="service"
        source={services}
        label={<HelpLabel label="Služba" code="DICT_ACT_SERVICE" />}
        labelMapper={dictionaryLabelMapper}
      />
      <FormSelect
        name="phases"
        label={<HelpLabel label="Fáze" code="DICT_ACT_PHASES" />}
        disabled={true}
        source={phaseOptions}
        valueIsId={true}
        multiple={true}
      />
    </>
  );
}
