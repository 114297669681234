/* eslint-disable prettier/prettier */
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const SUBMIT_REQUEST: MessageType<'SUCCESS' | 'ERROR'> = {
  SUCCESS: ['Formulář byl úspěšně odeslán.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba při odesílání formuláře', SnackbarVariant.ERROR],
};

export default { SUBMIT_REQUEST };
