import * as Yup from 'yup';
import { UFormGovAct, UFormGovService } from '../../models';

export function useValidationSchema() {
  return Yup.object<UFormGovAct>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    description: Yup.string().optional(),
    service: Yup.mixed<UFormGovService>().required('Povinné pole'),
  });
}
