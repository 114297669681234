import { TableFieldColumn, InlineTableFieldCells } from '@eas/common-web';
import { useMemo } from 'react';

export function useISDSColumns(): TableFieldColumn<string>[] {
  const ISDSCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'isds',
    isSubkey: false,
  });

  return useMemo(
    () => [
      {
        name: 'Datová schránka',
        datakey: '',
        width: 600,
        CellComponent: ISDSCell,
      },
    ],
    [ISDSCell]
  );
}

export function useCustomISDSColumns(): TableFieldColumn<string>[] {
  const ISDSCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'customIsds',
    isSubkey: true,
  });

  const CommentCell = InlineTableFieldCells.useInlineTextFieldFactory<string>({
    collectionDatakey: 'customIsds',
    isSubkey: true,
  });

  return useMemo(
    () => [
      {
        name: 'Datová schránka',
        datakey: 'isds',
        width: 400,
        CellComponent: ISDSCell,
      },
      {
        name: 'Komentář',
        datakey: 'comment',
        width: 400,
        CellComponent: CommentCell,
      },
    ],
    [ISDSCell, CommentCell]
  );
}
