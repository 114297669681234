import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAbortableFetch } from '../../utils/abortable-fetch';
import { fetchProfile } from '../profile/profile-api';
import { ProfileForm } from '../profile/profile-types';
import { UserContext } from '@eas/common-web';

interface UFormContextProps {
  profile: ProfileForm | undefined;
  loading: boolean;
}

const UFormContext = createContext<UFormContextProps | undefined>(undefined);

export const UFormProvider: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState<ProfileForm | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const getProfile = useAbortableFetch(async (fetch) => {
    try {
      setLoading(true);

      if (!user.id) {
        return;
      }

      fetch = fetchProfile(user.id);
      const profile: ProfileForm = await fetch.json();

      setProfile(profile);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  });

  useEffect(() => {
    if (user !== undefined && user.id !== null && !profile?.id && !loading) {
      getProfile?.();
    }
  }, [user, getProfile, profile?.id, loading]);

  return (
    <UFormContext.Provider value={{ profile, loading }}>
      {profile && children}
    </UFormContext.Provider>
  );
};

export const useUFormContext = () => {
  const context = useContext(UFormContext);
  if (context === undefined) {
    throw new Error('useUFormContext must be used within a UFormProvider');
  }
  return context;
};
