import React, { useContext } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  FormRichEditor,
  DetailContext,
  DetailHandle,
} from '@eas/common-web';
import { useEditHelpDialog } from './dialog-actions/edit-help-hook';
import { UFormGovAct } from '../../models';

export function UFormGovActActionbar() {
  const {
    source: { data },
  } = useContext<DetailHandle<UFormGovAct>>(DetailContext);
  const { canEditHelp, callApi: editHelpApiCall } = useEditHelpDialog();

  return (
    <>
      {canEditHelp && (
        <DetailActionButton
          promptKey="EDIT_HELP"
          apiCall={editHelpApiCall}
          buttonLabel="Upravit nápovědu"
          dialogTitle="Upravení nápovědy"
          dialogText="Skutečně chcete upravit nápovědu?"
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<HelpIcon />} />
          )}
          dialogWidth={1000}
          FormFields={() => (
            <>
              <FormRichEditor
                name="text"
                label=""
                required={true}
                labelOptions={{ hide: true }}
              />
            </>
          )}
          formInitialValues={{ text: data?.help }}
        />
      )}
    </>
  );
}
