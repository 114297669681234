import React, { useEffect, useContext, useState } from 'react';
import {
  FormTextField,
  FormAutocomplete,
  FormRichEditor,
  FormTextFieldMultiple,
  DetailContext,
  DetailHandle,
  FormCustomField,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { useUFormGovAgendaAutocomplete } from '../uform-gov-agenda/uform-gov-agenda-api';
import { UFormGovService, UFormGovActAutocomplete } from '../../models';
import { getActsByServiceId } from '../uform-gov-act/uform-gov-act-api';
import { DisabledComponent } from '../ovm/ovm-fields';

export function UFormGovServiceFields() {
  const [acts, setActs] = useState<UFormGovActAutocomplete[]>([]);
  const agendas = useUFormGovAgendaAutocomplete();
  const { source } = useContext<DetailHandle<UFormGovService>>(DetailContext);

  useEffect(() => {
    if (source.data?.id) {
      getActsByServiceId(source.data.id)
        .json()
        .then((acts) => {
          setActs(acts.items);
        });
    }
  }, [source.data?.id]);

  return (
    <>
      <FormCustomField
        name="acts"
        label={<HelpLabel label="Úkony" code="DICT_SERVICE_ACTS" />}
      >
        <DisabledComponent value={acts?.map((act) => act.name).join('; ')} />
      </FormCustomField>
      <FormTextField
        name="externalId"
        label={<HelpLabel label="Externí ID" code="DICT_SERVICE_EXTERNAL_ID" />}
      />
      <FormRichEditor
        name="description"
        label={<HelpLabel label="Popis" code="DICT_SERVICE_DESCRIPTION" />}
      />
      <FormRichEditor
        name="characterCs"
        label={
          <HelpLabel label="Detailní popis" code="DICT_SERVICE_CHARACTER_CS" />
        }
      />
      <FormRichEditor
        name="commonName"
        label={
          <HelpLabel
            label="Běžně používaný název"
            code="DICT_SERVICE_COMMON_NAME"
          />
        }
      />
      <FormRichEditor
        name="whenToSolve"
        label={
          <HelpLabel label="Kdy věc řešit" code="DICT_SERVICE_WHEN_TO_SOLVE" />
        }
      />
      <FormRichEditor
        name="whenToAct"
        label={<HelpLabel label="Kdy jednat" code="DICT_SERVICE_WHEN_TO_ACT" />}
      />
      <FormRichEditor
        name="wayOfProcessing"
        label={
          <HelpLabel
            label="Způsob vyřízení elektronicky"
            code="DICT_SERVICE_WAY_OF_PROCESSING"
          />
        }
      />
      <FormRichEditor
        name="serviceOutput"
        label={<HelpLabel label="Výstup služby" code="DICT_SERVICE_OUTPUT" />}
      />
      <FormRichEditor
        name="faq"
        label={<HelpLabel label="Časté dotazy" code="DICT_SERVICE_FAQ" />}
      />
      <FormRichEditor
        name="feedbackContact"
        label={
          <HelpLabel
            label="Kontakt na věcnou správu"
            code="DICT_SERVICE_FEEDBACK_CONTACT"
          />
        }
      />
      <FormRichEditor
        name="serviceValidFrom"
        label={
          <HelpLabel
            label="Platnost služby od"
            code="DICT_SERVICE_VALID_FROM"
          />
        }
      />
      <FormRichEditor
        name="serviceValidUntil"
        label={
          <HelpLabel
            label="Platnost služby do"
            code="DICT_SERVICE_VALID_UNTIL"
          />
        }
      />
      <FormRichEditor
        name="ownerOvm"
        label={
          <HelpLabel label="Vlastník služby" code="DICT_SERVICE_OWNER_OVM" />
        }
      />
      <FormTextFieldMultiple
        name="category"
        label={<HelpLabel label="Kategorie" code="DICT_SERVICE_CATEGORY" />}
      />
      <FormRichEditor
        name="note"
        label={<HelpLabel label="Poznámka" code="DICT_SERVICE_NOTE" />}
      />
      <FormAutocomplete
        name="agenda"
        source={agendas}
        label={<HelpLabel label="Agenda" code="DICT_SERVICE_AGENDA" />}
        labelMapper={dictionaryLabelMapper}
      />
    </>
  );
}
