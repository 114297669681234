import React from 'react';
import {
  FormTextField,
  FormPanel,
  FormAutocomplete,
  useFormSelector,
  FormCheckbox,
  FormFileField,
  FormSelect,
  useStaticListSource,
  FormTextArea,
  FormFileTable,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { UForm, UFormGovAgenda, UFormGovService } from '../../models';
import { PersonType } from '../../models/uform';
import { AddressField } from '../../components/form/address-field/address-field';
import { useUFormGovActAutocomplete } from '../uform-gov-act/uform-gov-act-api';
import { useUFormGovAgendaAutocomplete } from '../uform-gov-agenda/uform-gov-agenda-api';
import { useUFormGovServiceAutocomplete } from '../uform-gov-service/uform-gov-service-api';
import { useOVMAutocomplete } from '../ovm/ovm-api';
import { useAttachmentColumns } from '../uform-new/step-subject/step-subject-content';
import { uformLabelMapper } from './uform-utils';

export function UFormFields() {
  const {
    agenda,
    service,
    actorProxy,
    originatorPersonType,
    differentContactAddress,
  } = useFormSelector(
    (
      values: UForm & {
        agenda: UFormGovAgenda | null;
        service: UFormGovService | null;
        representedPerson: {
          differentContactAddress: boolean;
        };
      }
    ) => ({
      act: values.act,
      agenda: values.agenda,
      service: values.service,
      actorProxy: values.actor?.proxy,
      originatorPersonType: values.representedPerson?.type,
      differentContactAddress:
        values.representedPerson?.differentContactAddress,
    })
  );

  const agendas = useUFormGovAgendaAutocomplete();
  const services = useUFormGovServiceAutocomplete(agenda?.id || '');
  const acts = useUFormGovActAutocomplete(service?.id || '');
  const ovms = useOVMAutocomplete(service?.id || '');

  const representedPersonOptions = useStaticListSource([
    { id: PersonType.NATURAL_PERSON, name: 'Fyzická osoba' },
    { id: PersonType.BUSINESS_PERSON, name: 'Osoba s IČO' },
    { id: PersonType.FOREIGN_PERSON, name: 'Zahraniční osoba' },
  ]);

  const columns = useAttachmentColumns();

  return (
    <>
      <FormPanel label="Informace o službě">
        <FormAutocomplete
          name="act.service.agenda"
          label={<HelpLabel label="Agenda" code="UFORM_AGENDA" />}
          source={agendas}
          labelMapper={uformLabelMapper}
        />
        <FormAutocomplete
          name="act.service"
          label={<HelpLabel label="Služba" code="UFORM_SERVICE" />}
          source={services}
          labelMapper={uformLabelMapper}
        />
        <FormAutocomplete
          name="act"
          label={<HelpLabel label="Úkon" code="UFORM_ACT" />}
          source={acts}
          labelMapper={uformLabelMapper}
        />
        <FormAutocomplete
          name="recipient.authority"
          label={<HelpLabel label="OVM příjemce" code="UFORM_RECIPIENT" />}
          source={ovms}
        />
        <FormTextField
          name="recipient.databox"
          label={<HelpLabel label="Datová schránka" code="UFORM_ISDS" />}
        />
        <FormCheckbox
          name="recipient.isCustomDatabox"
          label={
            <HelpLabel
              label="Chci zadat odlišnou datovou schránku"
              code="UFORM_IS_CUSTOM_ISDS"
            />
          }
        />
        <FormTextField
          name="recipient.customDatabox"
          label={
            <HelpLabel
              label="Vlastní datová schránka"
              code="UFORM_CUSTOM_ISDS"
            />
          }
        />
        <FormTextField
          name="recipient.department"
          label={<HelpLabel label="K rukám / Odbor" code="UFORM_DEPARTMENT" />}
        />
      </FormPanel>
      <FormPanel label="Osoba činící úkon">
        <FormTextField
          name="actor.firstName"
          label={<HelpLabel label="Jméno" code="UFORM_FIRST_NAME" />}
        />
        <FormTextField
          name="actor.lastName"
          label={<HelpLabel label="Příjmení" code="UFORM_LAST_NAME" />}
        />
        <FormTextField
          name="actor.birthDate"
          label={<HelpLabel label="Datum narození" code="UFORM_BIRTH_DATE" />}
        />
        <FormSelect
          name="actor.preferredResponseChannel"
          label={
            <HelpLabel
              label="Preferovaný kanál pro odpověď "
              code="UFORM_PREFERRED_RESPONSE_CHANNEL"
            />
          }
          valueIsId={true}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          source={useStaticListSource([
            { id: 'ISDS', name: 'Datová schránka' },
            { id: 'MAIL', name: 'Pošta' },
          ])}
        />
        <FormTextField
          name="actor.email"
          label={<HelpLabel label="E-mail" code="UFORM_EMAIL" />}
        />
        <FormTextField
          name="actor.phone"
          label={<HelpLabel label="Telefonní číslo" code="UFORM_PHONE" />}
        />
        <FormCheckbox
          name="actor.proxy"
          label={
            <HelpLabel
              label="Úkon prováděn v zastoupení"
              code="UFORM_ACTING_ON_BEHALF"
            />
          }
        />
        {actorProxy && (
          <FormFileField
            name="actor.powerOfAttorney"
            label={
              <HelpLabel
                label="Plná moc"
                code="UFORM_POWER_OF_ATTORNEY"
                required
              />
            }
            accept={['.pdf']}
          />
        )}
        <FormSelect
          name="representedPerson.type"
          label={
            actorProxy ? (
              <HelpLabel
                label="Podání je činěno za"
                code="UFORM_SUBMISSION_FOR"
                required
              />
            ) : (
              <HelpLabel label="Podání činí" code="UFORM_SUBMISSION_FOR" />
            )
          }
          valueIsId={true}
          source={representedPersonOptions}
        />
      </FormPanel>

      {originatorPersonType === PersonType.NATURAL_PERSON && (
        <>
          <FormPanel label="Fyzická osoba">
            <FormTextField
              name="representedPerson.firstName"
              label={<HelpLabel label="Jméno" code="UFORM_FIRST_NAME" />}
            />
            <FormTextField
              name="representedPerson.lastName"
              label={<HelpLabel label="Příjmení" code="UFORM_LAST_NAME" />}
            />
            <FormTextField
              name="representedPerson.birthDate"
              label={
                <HelpLabel label="Datum narození" code="UFORM_BIRTH_DATE" />
              }
            />
            <FormTextField
              name="representedPerson.idNumber"
              label={
                <HelpLabel label="Číslo OP" code="UFORM_INDIVIDUAL_ID_NUMBER" />
              }
            />
            <AddressField name="representedPerson.address" />
            <FormCheckbox
              name="representedPerson.differentContactAddress"
              label={
                <HelpLabel
                  label="Kontaktní adresa je jiná než adresa trvalého pobytu"
                  code="UFORM_DIFFERENT_CONTACT_ADDRESS"
                />
              }
            />
          </FormPanel>
          {differentContactAddress && (
            <FormPanel label="Kontaktní adresa">
              <AddressField
                name="representedPerson.contactAddress"
                disableForeignCountry={true}
              />
            </FormPanel>
          )}
        </>
      )}

      {originatorPersonType === PersonType.BUSINESS_PERSON && (
        <FormPanel label="Osoba s IČO">
          <FormTextField
            name="representedPerson.ico"
            label={<HelpLabel label="IČO" code="UFORM_ICO" />}
          />
          <FormTextField
            name="representedPerson.companyName"
            label={<HelpLabel label="Název" code="UFORM_COMPANY_NAME" />}
          />
          <FormTextField
            name="representedPerson.databox"
            label={<HelpLabel label="Datová schránka" code="UFORM_DATA_BOX" />}
          />
          <AddressField
            name="representedPerson.contactAddress"
            disableForeignCountry={true}
          />
        </FormPanel>
      )}

      {originatorPersonType === PersonType.FOREIGN_PERSON && (
        <FormPanel label="Zahraniční osoba">
          <FormTextField
            name="representedPerson.ico"
            label={
              <HelpLabel
                label="Zahraniční identifikátor (obdoba IČO)"
                code="UFORM_FOREIGN_IDENTIFIER"
              />
            }
          />
          <FormTextField
            name="representedPerson.companyName"
            label={
              <HelpLabel label="Název" code="UFORM_FOREIGN_COMPANY_NAME" />
            }
          />
          <AddressField name="representedPerson.address" />

          <FormPanel label="Kontaktní adresa na území ČR">
            <AddressField
              name="representedPerson.contactAddress"
              disableForeignCountry={true}
            />
          </FormPanel>
        </FormPanel>
      )}

      <FormPanel label="Podání">
        <FormTextArea
          name="submission.text"
          label={
            <HelpLabel
              label="Popis žádosti"
              code="UFORM_APPLICATION_DESCRIPTION"
              required
            />
          }
          labelOptions={{
            hide: true,
          }}
          minRows={8}
        />
      </FormPanel>
      <FormPanel label="Přílohy">
        <FormFileTable
          name="submission.attachments"
          label="Přílohy"
          labelOptions={{ hide: true }}
          columns={columns}
        />
      </FormPanel>
    </>
  );
}
