import * as Yup from 'yup';
import { StepOriginatorForm } from './step-originator-types';
import { PersonType } from '../../../models/uform';
import { addressFieldSchema } from '../../../components/form/address-field/address-field-schema';
import { FileRef } from '@eas/common-web';
import { validateIco } from '../../subject/fields/subjects-utils';

export const stepOriginatorSchema = () =>
  Yup.object<StepOriginatorForm>().shape({
    actor: Yup.object({
      firstName: Yup.string().optional(),
      lastName: Yup.string().optional(),
      birthDate: Yup.string().optional(),
      preferredResponseChannel: Yup.mixed<'ISDS' | 'MAIL'>().optional(),
      email: Yup.string().optional(),
      phone: Yup.string().optional(),
      proxy: Yup.boolean().nullable(),
      powerOfAttorney: Yup.mixed<FileRef>()
        .nullable()
        .when('proxy', {
          is: true,
          then: Yup.mixed<FileRef>().required('Povinné pole'),
          otherwise: Yup.mixed<FileRef>().nullable(),
        }),
    }).optional(),
    representedPerson: Yup.object({
      type: Yup.mixed<PersonType>().optional(),
      firstName: Yup.mixed().when('type', {
        is: PersonType.NATURAL_PERSON,
        then: Yup.string().required('Povinné pole'),
      }),
      lastName: Yup.mixed().when('type', {
        is: PersonType.NATURAL_PERSON,
        then: Yup.string().required('Povinné pole'),
      }),
      birthDate: Yup.string().optional(),
      ico: Yup.mixed().when('type', {
        is: PersonType.BUSINESS_PERSON,
        then: validateIco,
      }),
      companyName: Yup.mixed().when('type', {
        is: PersonType.BUSINESS_PERSON,
        then: Yup.string().required('Povinné pole'),
      }),
      databox: Yup.string().optional(),
      idNumber: Yup.string().optional(),
      address: addressFieldSchema({
        country: {
          required: false,
        },
        municipality: {
          required: false,
        },
      }).optional(),
      differentContactAddress: Yup.boolean().nullable(),
      contactAddress: addressFieldSchema({
        country: {
          required: false,
        },
        municipality: {
          required: false,
        },
      })
        .optional()
        .nullable(),
    })
      .nullable()
      .test('Typ', '', function () {
        const { createError, parent } = this;

        if (parent?.actor?.proxy && !parent?.representedPerson?.type) {
          return createError({
            path: 'representedPerson.type',
            message: 'Povinné pole',
          });
        }
        return true;
      }),
  });
