import React from 'react';
import { Process } from '../../components/process/process';
import { useUFormRequests } from './uform-hook';
import { ProcessForm } from './uform-types';
import { TranslationKeys } from '../../enums';
import { UFormProvider, useUFormContext } from './uform-context';

export function UFormNew() {
  return (
    <UFormProvider>
      <UFormNewContent />
    </UFormProvider>
  );
}

function UFormNewContent() {
  const { profile } = useUFormContext();
  const { steps } = useUFormRequests(profile!);

  return (
    <Process<ProcessForm>
      steps={steps}
      title="Podání jednoduchého formuláře"
      translationKey={TranslationKeys.UFORM_REQUEST}
    />
  );
}
