import {
  abortableFetch,
  AbortableFetch,
  NavigationContext,
  PromptContext,
  SnackbarContext,
  useEventCallback,
  usePrompts,
  FormContext,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useRef, useState } from 'react';
import { ExitProcessAction } from '../../../components/process/process-actions';
import { ProcessActionsProps } from '../../../components/process/process-types';
import { EvidenceAPI, Messages } from '../../../enums';
import { ProcessForm } from '../uform-types';
import { getErrorMessage } from '../../../utils/get-message';
import { UForm } from '../../../models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      float: 'right',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

function callApi(body: Partial<ProcessForm>) {
  return abortableFetch(`${EvidenceAPI.UFORM}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  });
}

const PROMPT_KEY = 'CREATE_UFORM';

export function StepSummaryActions({
  handleNext,
  handleBack,
  handleExit,
  allValues,
  navigationPrompt,
}: ProcessActionsProps<ProcessForm>) {
  const classes = useStyles();

  /**
   * Loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);
  const { unregisterPrompt } = useContext(NavigationContext);

  /**
   * Fetch reference.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Prompt dialog.
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Odeslání jednoduchého formuláře',
      dialogText:
        'Formulář bude uložen a následně budete mít možnost provést další kroky, jako je jeho autorizace a finální odeslání. Chcete pokračovat?',
    },
  ]);

  const handleNextWithConfirm = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        try {
          setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }
          fetch.current = callApi({
            ...allValues,
            recipient: {
              ...allValues.recipient,
              databox:
                allValues.recipient?.customDatabox ||
                allValues.recipient?.databox,
            },
            submission: {
              ...allValues.submission,
              attachments: allValues.submission?.attachments?.map((a) => ({
                order: a.order,
                file: a,
              })) as any,
            },
          });

          const request = (await fetch.current.json()) as UForm;

          // const attachmentPromises = (
          //   allValues.applicationAttachments ?? []
          // ).map((file, index) =>
          //   createAttachment({
          //     form: {
          //       id: request.id,
          //     } as UForm,
          //     file,
          //     order: index,
          //   }).json()
          // );

          // await Promise.all(attachmentPromises);

          setFieldValue('request', request);

          unregisterPrompt(navigationPrompt);

          // navigate(EvidenceUrl.UFORM + '/' + request.id);

          handleNext();
        } catch (err) {
          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.UForm.SUBMIT_REQUEST,
              err.code
            );
            showSnackbar(...message);
          }
          console.log(err);
          return undefined;
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWithConfirm}
          className={classes.button}
          disableElevation
          disabled={loading}
          startIcon={
            loading && <CircularProgress size="20px" color="inherit" />
          }
        >
          Pokračovat
        </Button>
        <Button
          onClick={handleBack}
          className={classes.button}
          disableElevation
          disabled={loading}
        >
          Předchozí
        </Button>
        <ExitProcessAction handleExit={handleExit} disabled={loading} />
      </div>
    </div>
  );
}
