import {
  useAutocompleteSource,
  ApiFilterOperation,
  abortableFetch,
} from '@eas/common-web';
import { UFormGovActAutocomplete } from '../../models';
import { EvidenceAPI } from '../../enums';

export function useUFormGovActAutocomplete(serviceId?: string) {
  const params = serviceId
    ? {
        filters: [
          {
            operation: ApiFilterOperation.EQ,
            field: 'service.id',
            value: serviceId,
          },
        ],
      }
    : {};

  return useAutocompleteSource<UFormGovActAutocomplete>({
    url: `${EvidenceAPI.UFORM_GOV_ACT}/autocomplete`,
    params,
  });
}

export function getActById(id: string) {
  return abortableFetch(`${EvidenceAPI.UFORM_GOV_ACT}/${id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
}

export function getActsByServiceId(serviceId: string) {
  return abortableFetch(`${EvidenceAPI.UFORM_GOV_ACT}/autocomplete`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'service.id',
          value: serviceId,
        },
      ],
      size: 2,
    }),
  });
}
