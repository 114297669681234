import { DomainObject } from '@eas/common-web';
import { IdentityProvider } from '../enums';
import { DatedObject } from './dated-object';
import { DomainObjectAutocomplete } from './domain-object';
import { PersonalData } from './personal-data';
import { PhoneNumber } from './phone-number';
import { RoleInstance } from './role-instance';
import { TwoFactorDetails } from './two-factor-details';
import { IdentityDocument } from './subject';
import { Address } from './address';

/**
 * Typ průkazu
 */
export enum IdType {
  /**
   * Občanský průkaz
   */
  ID_CARD = 'ID_CARD',

  /**
   * Pas
   */
  PASSPORT = 'PASSPORT',
}

export interface UserAutocomplete extends DomainObjectAutocomplete {
  /**
   * Uživatelské jméno
   */
  username?: string;

  label?: string;
}

/**
 * Uživatel
 */
export interface User extends DatedObject {
  /**
   * Název
   */
  name?: string;

  /**
   * Uživatelské jméno
   */
  username?: string;

  /**
   * Heslo (hash)
   */
  password?: string;

  /**
   * Poslední přihlášení
   */
  lastLogin?: UserLogin;

  /**
   * Detaily dvoufaktorové autenitizace
   */
  twoFactorDetails?: TwoFactorDetails;

  /**
   * Titul před
   */
  degreeBefore?: PersonalData;

  /**
   * Jméno
   */
  firstName?: PersonalData;

  /**
   * Příjmení
   */
  lastName?: PersonalData;

  /**
   * Titul za
   */
  degreeAfter?: PersonalData;

  /**
   * Číslo OP
   */
  idNumber?: PersonalData;

  /**
   * E-mail
   */
  email?: PersonalData;

  /**
   * Telefonní číslo
   */
  phoneNumber?: PhoneNumber;

  /**
   * Přiřazené role
   */
  roleInstances?: RoleInstance[];

  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Datum zneplatnění
   */
  validTo?: string;

  /**
   * Externí identifkátory
   */
  externalIds?: UserExternalId[];

  /**
   * Souhlas se zpracováním osobních ůdajů
   */
  personalDataProcessingConsent?: boolean;

  /**
   * Souhlas s odesíláním kampaní
   */
  mailingConsent?: boolean;

  /**
   * Systémový účet
   */
  systemAccount?: boolean;

  /**
   * Příznak, zda-li byl udělen souhlas s nahlížením na data (dříve souhlas s rolemi).
   * <p>
   * Význam jednotlivých hodnot:
   * <ul>
   *     <li>{@code true} - souhlas je vyžadován a byl udělen</li>
   *     <li>{@code false} - souhlas je vyžadován a ještě nebyl udělen</li>
   *     <li>{@code null} - souhlas není vyžadován, uživatel nemá žádnou roli, která by vyžadovala udělení souhlasu</li>
   * </ul>
   */
  roleConsent?: boolean;

  /**
   * Identifikátory datové schránky (ISDS)
   */
  isdsIds?: string[];

  /**
   * Doklady
   */
  identityDocuments?: IdentityDocument[];

  /**
   * Adresa
   */
  residence?: Address;
}

/**
 * Identifikátor z externího poskytovatele identity
 */
export interface UserExternalId {
  id?: string;

  /**
   * Poskytovatel identity (IdP)
   */
  identityProvider?: IdentityProvider;

  /**
   * Identifikátor
   */
  externalId?: string;
}

/**
 * Oprávnení
 */
interface Role extends DomainObject {
  /**
   * Název
   */
  name: string;
}

/**
 * Prihlášený uživatel
 */
export interface Me extends DomainObject {
  /**
   * Jméno
   */
  name: string;

  /**
   * Role
   */
  roles: Role[];

  /**
   * Bezpečnostní oprávnění
   */
  securityPermissions: string[];

  /**
   * Má externí identifikátor NIA
   */
  hasNiaExternalId: boolean;

  /**
   * Subjektové oprávnění
   */
  subjectPermissions: {
    [subject: string]: {
      empowermentPermissions: string[];
      relationships: ('ADMINISTRATOR' | 'USER')[];
      roles: Role[];
      subjectPermissions: string[];
    };
  };
}

/**
 * Interní stav pro funkci "hasPermission"
 */
export interface PermissionsState {
  /**
   * Id prohledávaného subjektu
   */
  subjectId?: string;

  /**
   * Příznak zda-li se mají prohledávat také subjektové oprávnění
   */
  searchInSubjectPerms?: boolean;

  /**
   * Příznak zda-li se mají prohledávat jen subjektové oprávnění
   */
  searchOnlyInSubjectPerms?: boolean;
}

/**
 * Registrace uživatele
 */
export interface UserRegistration extends DatedObject {
  /**
   * Titul před
   */
  degreeBefore?: PersonalData;

  /**
   * Uživatelské jméno
   */
  username?: string;

  /**
   * Jméno
   */
  firstName?: PersonalData;

  /**
   * Příjmení
   */
  lastName?: PersonalData;

  /**
   * Titul za
   */
  degreeAfter?: PersonalData;

  /**
   * E-mail
   */
  email?: PersonalData;

  /**
   * Telefonní číslo
   */
  phoneNumber?: PhoneNumber;
}

/**
 * Přihlášení
 */
interface UserLogin extends DomainObject {
  /**
   * Uživatel
   */
  user?: User;

  /**
   * Poskytovatel identity (IdP)
   */
  identityProvider?: IdentityProvider;

  /**
   * Časová známka
   */
  timestamp?: string;
}
