import React from 'react';
import {
  FormTextField,
  FormInlineTableField,
  FormAutocomplete,
  useAutocompleteSource,
  DictionaryAutocomplete,
} from '@eas/common-web';
import { HelpLabel } from '../../components/help/help-label';
import { useISDSColumns, useCustomISDSColumns } from './ovm-columns';
import Typography from '@material-ui/core/Typography';

export function DisabledComponent({ value }: { value: string | null }) {
  if (!value) {
    return null;
  }

  const values = value?.split('; ');

  return (
    <ul>
      {values?.map((value) => (
        <Typography
          key={value}
          variant="body1"
          component="li"
          style={{ margin: '0 1em' }}
        >
          {value}
        </Typography>
      ))}
    </ul>
  );
}

export function OVMFields() {
  const isdsColumns = useISDSColumns();
  const preferredIsdsColumns = useCustomISDSColumns();
  const dummySource = useAutocompleteSource<DictionaryAutocomplete>({
    url: '',
  });

  return (
    <>
      <FormTextField
        name="ico"
        label={<HelpLabel label="IČO" code="OVM_ICO" />}
      />
      <FormInlineTableField<string>
        name="isds"
        label={<HelpLabel label="Datové schránky" code="OVM_ISDS" />}
        columns={isdsColumns}
        initNewItem={() => ''}
      />
      <FormInlineTableField<string>
        name="customIsds"
        label={
          <HelpLabel
            label="Preferované datové schránky"
            code="OVM_CUSTOM_ISDS"
          />
        }
        columns={preferredIsdsColumns}
        initNewItem={() => ''}
      />
      <FormAutocomplete
        name="categories"
        source={dummySource}
        label={<HelpLabel label="Kategorie" code="OVM_CATEGORIES" />}
        labelMapper={(item) => item.name}
        DisabledComponent={DisabledComponent}
        multiple={true}
      />
    </>
  );
}
