import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { useValidationSchema } from './uform-gov-act-schema';
import { UFormGovAct } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { UFormGovActFields } from './uform-gov-act-fields';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { UFormGovActActionbar } from './uform-gov-actionbar';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';

export function UFormGovActs() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<UFormGovAct>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDictionaryEvidence<UFormGovAct>({
    identifier: PageKey.UFORM_GOV_ACT,
    apiProps: {
      url: EvidenceAPI.UFORM_GOV_ACT,
    },
    tableProps: {
      tableName: 'Úkony',
      reportTag: ExportTags.UFORM_GOV_ACT,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'ACT',
      validationSchema,
      GeneralFieldsComponent: UFormGovActFields,
      toolbarProps: {
        title: 'Úkon',
        showBtn: permissions,
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.UFORM_GOV_ACT}
              CustomActions={UFormGovActActionbar}
            />
          );
        },
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
