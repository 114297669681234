import {
  FormPanel,
  FormTextArea,
  FormFileTable,
  TableFieldColumn,
  FileRef,
  TableFieldCells,
  FileTableActionCell,
  FormContext,
} from '@eas/common-web';
import React, { useContext, useState, useEffect } from 'react';
import { HelpLabel } from '../../../components/help/help-label';
import { useIntl } from 'react-intl';
import { StepSubjectForm } from './step-subject-types';
import { HelpBlock2 } from '../step-agenda/step-agenda-content';
import { ProcessForm } from '../uform-types';
import { UFormGovService } from '../../../models';
import { getServiceById } from '../../uform-gov-service/uform-gov-service-api';

export function useAttachmentColumns(): TableFieldColumn<FileRef>[] {
  const intl = useIntl();

  const columns: TableFieldColumn<FileRef>[] = [
    {
      name: intl.formatMessage({
        id: 'EAS_FILE_TABLE_COLUMN_ACTIONS',
        defaultMessage: 'Akce',
      }),
      datakey: 'id',
      width: 100,
      CellComponent: FileTableActionCell,
    },
    {
      name: intl.formatMessage({
        id: 'EAS_FILE_TABLE_COLUMN_ORDER',
        defaultMessage: 'Číslo',
      }),
      datakey: 'order',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'EAS_FILE_TABLE_COLUMN_NAME',
        defaultMessage: 'Název',
      }),
      datakey: 'file.name',
      width: 400,
      CellComponent: function FileNameCell({ rowValue, ...props }) {
        return (
          <TableFieldCells.TextCell
            {...props}
            rowValue={rowValue}
            value={`${rowValue.name || (rowValue as any)?.file?.name}`}
          />
        );
      },
    },
    {
      name: intl.formatMessage({
        id: 'EAS_FILE_TABLE_COLUMN_CREATED',
        defaultMessage: 'Nahráno',
      }),
      datakey: 'created',
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];

  return columns;
}

export function StepSubjectContent({ allValues }: { allValues: ProcessForm }) {
  const { setFieldValue } = useContext<FormContext<StepSubjectForm>>(
    FormContext
  );

  const columns = useAttachmentColumns();

  const [serviceDetail, setService] = useState<UFormGovService | null>(null);

  useEffect(() => {
    if (allValues.service) {
      getServiceById(allValues.service.id)
        .json()
        .then((service) => setService(service as UFormGovService));
    } else {
      setService(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.service?.id]);

  return (
    <>
      {serviceDetail?.help && <HelpBlock2 content={serviceDetail?.help} />}
      <FormPanel label="Podání">
        <FormTextArea
          name="submission.text"
          label={
            <HelpLabel
              label="Popis žádosti"
              code="DICT_FORM_APPLICATION_DESCRIPTION"
              required
            />
          }
          labelOptions={{
            hide: true,
          }}
          minRows={8}
        />
      </FormPanel>
      <FormPanel label="Přílohy">
        <FormFileTable
          name="submission.attachments"
          label="Přílohy"
          labelOptions={{ hide: true }}
          notifyChange={(value) => {
            const files = (value ?? []) as FileRef[];

            files.forEach((_, index) => {
              setFieldValue(
                `submission.attachments[${index}].order`,
                index + 1
              );
            });
          }}
          columns={columns}
        />
      </FormPanel>
    </>
  );
}
