import {
  FormPanel,
  FormAutocomplete,
  useFormSelector,
  FormContext,
  Panel,
  FormSelect,
  useStaticListSource,
  DictionaryAutocomplete,
  FormCheckbox,
  FormTextField,
} from '@eas/common-web';
import React, { useContext, useState, useEffect } from 'react';
import { HelpLabel } from '../../../components/help/help-label';
import {
  useUFormGovActAutocomplete,
  getActById,
  getActsByServiceId,
} from '../../uform-gov-act/uform-gov-act-api';
import { StepAgendaForm } from './step-agenda-types';
import { useUFormGovAgendaAutocomplete } from '../../uform-gov-agenda/uform-gov-agenda-api';
import {
  useUFormGovServiceAutocomplete,
  getServiceById,
  getServicesByAgendaId,
} from '../../uform-gov-service/uform-gov-service-api';
import { UFormGovAct, UFormGovService, OVM } from '../../../models';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './step-agenda-styles';
import {
  useOVMAutocomplete,
  getOVMbyServiceId,
  getOVMById,
} from '../../ovm/ovm-api';
import { uformLabelMapper } from '../../uform/uform-utils';
import Button from '@material-ui/core/Button';

function HelpBlock({
  label,
  content,
  defaultExpanded,
}: {
  label: string;
  content: string;
  defaultExpanded: boolean;
}) {
  const classes = useStyles();

  return (
    <Panel
      label={label}
      sideBorder={true}
      defaultExpanded={defaultExpanded}
      expandable={true}
      className={classes.helpPanel}
    >
      <Typography variant="body1" component="div" className={classes.editor}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Typography>
    </Panel>
  );
}

export function HelpBlock2({ content }: { content: string }) {
  const [expanded, setExpanded] = useState(false);
  const maxPreviewLines = 3;
  const lineHeight = 1.5; // Adjust as needed
  const maxHeight = `${maxPreviewLines * lineHeight}em`;

  const classes = useStyles();

  return (
    <div
      style={{
        margin: '1rem 0',
        padding: '0.5rem 1rem 1rem',
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        backgroundColor: '#f7fafc',
        width: '100%',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          transition: 'max-height 0.3s',
          maxHeight: expanded ? '100%' : maxHeight,
        }}
      >
        <Typography variant="body1" component="div" className={classes.editor}>
          <b>Nápověda</b>
          <br />
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Typography>
      </div>
      {content.length > 100 && (
        <Button
          size="medium"
          color="primary"
          onClick={() => setExpanded(!expanded)}
          style={{ marginTop: '1rem' }}
        >
          {expanded ? 'Zobrazit méně' : 'Zobrazit více'}
        </Button>
      )}
    </div>
  );
}

export function StepAgendaContent() {
  const {
    agenda,
    service,
    act,
    recipientAuthority,
    isCustomDatabox,
  } = useFormSelector((values: StepAgendaForm) => ({
    agenda: values.agenda,
    service: values.service,
    act: values.act,
    recipientAuthority: values.recipient?.authority,
    isCustomDatabox: values.recipient?.isCustomDatabox,
  }));
  const { setFieldValue } = useContext<FormContext<StepAgendaForm>>(
    FormContext
  );

  const [serviceDetail, setService] = useState<UFormGovService | null>(null);
  const [actDetail, setAct] = useState<UFormGovAct | null>(null);
  const [ovm, setOvm] = useState<OVM | null>(null);

  const agendas = useUFormGovAgendaAutocomplete();
  const services = useUFormGovServiceAutocomplete(agenda?.id || '');
  const acts = useUFormGovActAutocomplete(service?.id || '');
  const ovms = useOVMAutocomplete(service?.id || '');

  useEffect(() => {
    if (agenda) {
      getServicesByAgendaId(agenda.id)
        .json()
        .then((services) => {
          if (services?.items?.length === 1) {
            setFieldValue('service', {
              ...services.items[0],
              help:
                'Morbi vestibulum volutpat enim. Quisque id odio. Praesent ut ligula non mi varius sagittis. Maecenas malesuada. Sed a libero. Morbi vestibulum volutpat enim. Quisque id odio. Praesent ut ligula non mi varius sagittis. Maecenas malesuada. Sed a libero. Morbi vestibulum volutpat enim. Quisque id odio. Praesent ut ligula non mi varius sagittis. Maecenas malesuada. Sed a libero.',
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda?.id]);

  useEffect(() => {
    if (service) {
      getServiceById(service.id)
        .json()
        .then((service) => setService(service as UFormGovService));

      getActsByServiceId(service.id)
        .json()
        .then((acts) => {
          if (acts?.items?.length === 1) {
            setFieldValue('act', acts.items[0]);
          }
        });

      getOVMbyServiceId(service.id)
        .json()
        .then((ovms) => {
          if (ovms?.items?.length === 1) {
            setFieldValue('recipient.authority', ovms.items[0]);
          }
        });
    } else {
      setService(null);
    }
  }, [service?.id]);

  useEffect(() => {
    if (act) {
      getActById(act.id)
        .json()
        .then((act) => setAct(act as UFormGovAct));
    } else {
      setAct(null);
    }
  }, [act?.id]);

  useEffect(() => {
    if (recipientAuthority) {
      getOVMById(recipientAuthority.id)
        .json()
        .then((ovm: OVM) => {
          setOvm(ovm);

          if (!!ovm.customIsds?.length && ovm.customIsds?.length === 1) {
            setFieldValue('recipient.databox', ovm?.customIsds[0].isds);
          } else if (!ovm.customIsds?.length && ovm.isds?.length === 1) {
            setFieldValue('recipient.databox', ovm?.isds[0]);
          }
        });
    } else {
      setOvm(null);
    }
  }, [recipientAuthority?.id]);

  const customIsdsOptions = useStaticListSource<DictionaryAutocomplete>(
    ovm?.customIsds?.map((isds) => ({
      id: isds.isds,
      name: `${isds.isds} - ${isds.comment}`,
    })) || []
  );

  const ovmIsdsOptions = useStaticListSource<DictionaryAutocomplete>(
    ovm?.isds?.map((isds) => ({
      id: isds,
      name: isds,
    })) || []
  );

  const isdsOptions =
    customIsdsOptions.items.length > 0 ? customIsdsOptions : ovmIsdsOptions;

  return (
    <FormPanel label="Agenda">
      <FormAutocomplete
        name="agenda"
        label={<HelpLabel label="Agenda" code="UFORM_AGENDA" required />}
        source={agendas}
        notifyChange={() => {
          setFieldValue('service', null);
          setFieldValue('act', null);
          setFieldValue('recipient.authority', null);
          setFieldValue('recipient.databox', null);
          setFieldValue('recipient.isCustomDatabox', null);
          setFieldValue('recipient.customDatabox', null);
        }}
        labelMapper={uformLabelMapper}
      />
      <FormAutocomplete
        name="service"
        label={<HelpLabel label="Služba" code="UFORM_SERVICE" required />}
        source={services}
        disabled={!agenda}
        notifyChange={async () => {
          setFieldValue('act', null);
          setFieldValue('recipient.authority', null);
          setFieldValue('recipient.databox', null);
          setFieldValue('recipient.isCustomDatabox', null);
          setFieldValue('recipient.customDatabox', null);
        }}
        labelMapper={uformLabelMapper}
      />
      {serviceDetail?.help && <HelpBlock2 content={serviceDetail?.help} />}
      <FormAutocomplete
        name="act"
        label={<HelpLabel label="Úkon" code="UFORM_ACT" required />}
        source={acts}
        disabled={!service}
        labelMapper={uformLabelMapper}
      />
      {actDetail?.help && <HelpBlock2 content={actDetail?.help} />}
      <FormAutocomplete
        name="recipient.authority"
        label={
          <HelpLabel
            label="Úřad příslušný k vyřízení"
            code="UFORM_RECIPIENT"
            required
          />
        }
        source={ovms}
        disabled={!service}
        notifyChange={async () => {
          setFieldValue('recipient.databox', null);
          setFieldValue('recipient.isCustomDatabox', null);
          setFieldValue('recipient.customDatabox', null);
        }}
      />
      {!isCustomDatabox && (
        <FormSelect
          name="recipient.databox"
          label={
            <HelpLabel
              label="Datová schránka"
              code="UFORM_DATABOX"
              required={!isCustomDatabox}
            />
          }
          disabled={!ovm}
          source={isdsOptions}
          valueIsId={true}
        />
      )}
      <FormCheckbox
        name="recipient.isCustomDatabox"
        label={
          <HelpLabel
            label="Chci zadat odlišnou datovou schránku"
            code="UFORM_IS_CUSTOM_DATABOX"
          />
        }
        disabled={!ovm}
        notifyChange={() => {
          setFieldValue('recipient.databox', null);
          setFieldValue('recipient.customDatabox', null);
        }}
      />
      {isCustomDatabox && (
        <FormTextField
          name="recipient.customDatabox"
          label={
            <HelpLabel
              label="Vlastní datová schránka"
              code="UFORM_CUSTOM_DATABOX"
              required={isCustomDatabox}
            />
          }
        />
      )}
      <FormTextField
        name="recipient.department"
        label={<HelpLabel label="K rukám / Odbor" code="UFORM_DEPARTMENT" />}
      />

      {serviceDetail?.characterCs && (
        <HelpBlock
          label="Detailní popis služby"
          content={serviceDetail?.characterCs}
          defaultExpanded={false}
        />
      )}
    </FormPanel>
  );
}
