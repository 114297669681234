export enum TranslationKeys {
  BOILER_MANUFACTURER_NEW = 'BOILER_MANUFACTURER_NEW',
  SUBJECT_REGISTRATION = 'SUBJECT_REGISTRATION',
  EMPOWERMENT_REQUEST = 'EMPOWERMENT_REQUEST',
  IRZ_FACILITY_MERGE = 'IRZ_FACILITY_MERGE',
  IRZ_FACILITY_SPLIT = 'IRZ_FACILITY_SPLIT',
  IRZ_FACILITY_REQUEST_CHANGE = 'IRZ_FACILITY_REQUEST_CHANGE',
  IRZ_FACILITY_REQUEST_NEW = 'IRZ_FACILITY_REQUEST_NEW',
  IRZ_FACILITY_REQUEST_OPERATOR_CHANGE = 'IRZ_FACILITY_REQUEST_OPERATOR_CHANGE',
  OVZ_FACILITY_NEW = 'OVZ_FACILITY_NEW',
  OVZ_FACILITY_REQUEST_OPERATOR_CHANGE = 'OVZ_FACILITY_REQUEST_OPERATOR_CHANGE',
  PRODUCTION_SITE_MERGE = 'PRODUCTION_SITE_MERGE',
  OZO_PERMISSION_NEW = 'OZO_PERMISSION_NEW',
  ROLE_REQUEST = 'ROLE_REQUEST',
  PRODUCTION_SITE_SPLIT = 'PRODUCTION_SITE_SPLIT',
  UFORM_REQUEST = 'UFORM_REQUEST',
}
