import { AuthoredObject } from './authored-object';
import { Address } from './address';
import { DictionaryAutocomplete, FileRef } from '@eas/common-web';
import { UFormGovAct } from './uform-gov-act';
import { OVM } from './ovm';
import { UFormAttachment } from './uform-attachment';

export interface UFormRecipient {
  /**
   * OVM
   */
  authority?: OVM;

  /**
   * Vybraná/zadaná datová schránka
   */
  databox?: string;

  /**
   * K rukám / Odbor
   */
  department?: string;
}

/**
 * Osoba činící úkon
 */
export interface UFormActor {
  /**
   * Jméno
   */
  firstName?: string;

  /**
   * Příjmení
   */
  lastName?: string;

  /**
   * Datum narození
   */
  birthDate?: string;

  /**
   * Číslo OP
   */
  idNumber?: string;

  /**
   * Adresa trvalého pobytu
   */
  address?: Address;

  /**
   * Kontaktní adresa
   */
  contactAddress?: Address;

  /**
   * Email
   */
  email?: string;

  /**
   * Telefonní číslo
   */
  phone?: string;

  /**
   * Datová schránka
   */
  databox?: string;

  /**
   * Preferovaný kanál pro odpověď
   */
  preferredResponseChannel?: string;

  /**
   * Úkon prováděn v zastoupení
   */
  proxy?: boolean;

  /**
   * Plná moc
   */
  powerOfAttorney?: FileRef;
}

/**
 * Zastupovaná osoba (podání je činěno za)
 */
export interface UFormRepresentedPerson {
  /**
   * Typ osoby
   */
  type?: PersonType;

  /**
   * Jméno
   */
  firstName?: string;

  /**
   * Příjmení
   */
  lastName?: string;

  /**
   * Datum narození
   */
  birthDate?: string;

  /**
   * Číslo OP
   */
  idNumber?: string;

  /**
   * Adresa trvalého pobytu / sídla
   */
  address?: Address;

  /**
   * Kontaktní adresa
   */
  contactAddress?: Address;

  /**
   * IČO
   */
  ico?: string;

  /**
   * Název
   */
  companyName?: string;

  /**
   * Datová schránka
   */
  databox?: string;
}

export interface UFormSubmission {
  /**
   * Text podání
   */
  text?: string;

  /**
   * Přílohy
   */
  attachments?: UFormAttachment[];
}

/**
 * Autorizace
 */
export interface UFormAuthorization {
  /**
   * ID autorizace v EnviIAM
   */
  id?: string;

  /**
   * Čas autorizace v EnviIAM
   */
  time?: string;
}

/**
 * Formulář
 */
export interface UForm extends AuthoredObject {
  /**
   * Název formuláře
   */
  name?: string;

  /**
   * Úkon
   */
  act?: UFormGovAct;

  /**
   * Příjemce
   */
  recipient?: UFormRecipient;

  /**
   * Osoba činící úkon
   */
  actor?: UFormActor;

  /**
   * Zastupovaná osoba (podání je činěno za)
   */
  representedPerson?: UFormRepresentedPerson;

  /**
   * Předmět podání
   */
  submission?: UFormSubmission;

  /**
   * Autorizace
   */
  authorization?: UFormAuthorization;

  /**
   * PDF soubor
   */
  pdfFile?: FileRef;

  /**
   * SHA256 hash PDF dokumentu
   */
  pdfFileHash?: string;

  /**
   * Autorizační potvrzení
   */
  certificatePdfFile?: FileRef;
}

/**
 * Typ osoby
 */
export enum PersonType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  BUSINESS_PERSON = 'BUSINESS_PERSON',
  FOREIGN_PERSON = 'FOREIGN_PERSON',
}

export type UFormAutocomplete = DictionaryAutocomplete;
