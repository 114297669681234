import React, { useContext } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  DetailContext,
  DetailHandle,
  FormInlineTableField,
} from '@eas/common-web';
import { useEditCustomIsdsDialog } from './dialog-actions/edit-custom-isds-hook';
import { OVM } from '../../models';
import { HelpLabel } from '../../components/help/help-label';
import { useCustomISDSColumns } from './ovm-columns';

export function OVMActActionbar() {
  const {
    source: { data },
  } = useContext<DetailHandle<OVM>>(DetailContext);
  const {
    canEditCustomIsds,
    callApi: editCustomIsdsApiCall,
  } = useEditCustomIsdsDialog();

  const isdsColumns = useCustomISDSColumns();

  return (
    <>
      {canEditCustomIsds && (
        <DetailActionButton
          promptKey="EDIT_CUSTOM_ISDS"
          apiCall={editCustomIsdsApiCall}
          buttonLabel="Upravit preferované datové schránky"
          dialogTitle="Upravení preferovaných datových schránek"
          dialogText="Skutečně chcete upravit preferované datové schránky?"
          ButtonComponent={(props) => (
            <PrimaryDetailActionbarButton {...props} startIcon={<HelpIcon />} />
          )}
          dialogWidth={1000}
          FormFields={() => (
            <>
              <FormInlineTableField<string>
                name="customIsds"
                label={<HelpLabel label="Datové schránky" code="OVM_ISDS" />}
                columns={isdsColumns}
                initNewItem={() => ''}
              />
            </>
          )}
          formInitialValues={{ customIsds: data?.customIsds }}
        />
      )}
    </>
  );
}
