import React, { useContext } from 'react';
import { noop } from 'lodash';
import GetAppIcon from '@material-ui/icons/GetApp';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DetailContext, DetailHandle, FilesContext } from '@eas/common-web';
import { UForm } from '../../models';

const useStyles = makeStyles({
  actionbarButton: {
    minWidth: 40,
    width: 'max-content',
    cursor: 'pointer',
    marginTop: 1,
    marginLeft: 6,
    backgroundColor: '#e0e0e0',
    border: 0,
    '&:hover': {
      backgroundColor: '#d0d0d0',
    },
  },
});

export function UFormActionbar() {
  const classes = useStyles();
  const { getFileUrl } = useContext(FilesContext);

  const { source } = useContext<DetailHandle<UForm>>(DetailContext);

  return (
    <div style={{ flexWrap: 'wrap' }}>
      {source.data?.pdfFile?.id && (
        <MuiButton
          startIcon={<GetAppIcon />}
          classes={{ root: classes.actionbarButton }}
          onClick={noop}
          download={true}
          href={getFileUrl(source.data.pdfFile.id)}
          variant="outlined"
          size="small"
        >
          <Typography variant="body2">Stáhnout PDF</Typography>
        </MuiButton>
      )}
      {source.data?.certificatePdfFile?.id && (
        <MuiButton
          startIcon={<GetAppIcon />}
          classes={{ root: classes.actionbarButton }}
          onClick={noop}
          download={true}
          href={getFileUrl(source.data.certificatePdfFile.id)}
          variant="outlined"
          size="small"
        >
          <Typography variant="body2">
            Stáhnout autorizační potvrzení
          </Typography>
        </MuiButton>
      )}
    </div>
  );
}
