/**
 * UForm permissions
 */
export enum PermissionUForm {
  /** Tvorba vlastních formulářů obecných podání */
  UFORM_CREATE = 'UFORM_CREATE',

  /** Editace vlastních formulářů obecných podání */
  UFORM_UPDATE = 'UFORM_UPDATE',

  /** Smazání vlastních formulářů obecných podání */
  UFORM_DELETE = 'UFORM_DELETE',

  /** Přehled všech formulářů obecných podání */
  UFORM_LIST_ALL = 'UFORM_LIST_ALL',

  /** Přehled vlastních formulářů obecných podání */
  UFORM_LIST_OWN = 'UFORM_LIST_OWN',

  /** Detail všech formulářů obecných podání */
  UFORM_DETAIL_ALL = 'UFORM_DETAIL_ALL',

  /** Detail vlastních formulářů obecných podání */
  UFORM_DETAIL_OWN = 'UFORM_DETAIL_OWN',
}
