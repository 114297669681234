import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get } from 'lodash';
import {
  useAutocompleteSource,
  useEventCallback,
  useFormSelector,
  AbortableFetch,
  abortableFetch,
  ListSource,
  FormContext,
  useUpdateEffect,
  FormAutocomplete,
  Filter,
  ApiFilterOperation,
  FormTextField,
  DetailMode,
  DetailContext,
} from '@eas/common-web';
import { AddressFieldCellProps } from './address-field-types';
import { AddressFieldContext } from './address-field-context';
import { Address, RuianObject, RuianObjectAutocomplete } from '../../../models';
import { HelpLabel } from '../../help/help-label';

function callApi(url: string, filters: Filter[]) {
  return abortableFetch(`${url}/autocomplete`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      filters,
    }),
  });
}

export function AddressFieldCell({
  code,
  label,
  required,
  disablePrefill = false,
  disabled = () => false,
  url,
  parentUrl,
  name,
  parentName,
  customName,
  descendantNames = [],
  createFilter = () => [],
  filterDeps = () => [],
  notifyChange = () => {},
  before = () => null,
}: AddressFieldCellProps) {
  /**
   * Ctx stuff.
   */
  const addressFieldCtx = useContext(AddressFieldContext);
  const { setFieldValue, editing } = useContext(FormContext);
  const detailCtx = useContext(DetailContext);

  /**
   * Full address object.
   */
  const { address } = useFormSelector(
    (data: { [key: string]: Address | undefined }) => ({
      address: get(data, addressFieldCtx.name),
    })
  );

  /**
   * Field value.
   */
  const value = useMemo(() => address?.[name] as RuianObject | undefined, [
    address,
    name,
  ]);

  const [emptySource, setEmptySource] = useState(false);
  const source = useAutocompleteSource<RuianObjectAutocomplete>({
    url: `${url}/autocomplete`,
  });

  /**
   * Callback to handle the prefilling of the value based on the current filters.
   */
  const prefillFetchRef = useRef<AbortableFetch | null>(null);
  const callPrefill = useEventCallback(async () => {
    if (prefillFetchRef.current) {
      prefillFetchRef.current.abort();
    }

    const filters = createFilter(address) ?? [];
    prefillFetchRef.current = callApi(url, filters);

    const json: ListSource<RuianObjectAutocomplete> = await prefillFetchRef.current.json();
    const options = json.items;

    setEmptySource(options.length === 0);

    if (options.length === 1) {
      setFieldValue(`${addressFieldCtx.name}.${name}`, options[0]);
    }
  });

  /**
   * Callback to prefill the parent of current value.
   */
  const prefillParentFetchRef = useRef<AbortableFetch | null>(null);
  const callPrefillParent = useEventCallback(async () => {
    if (parentUrl) {
      if (prefillParentFetchRef.current) {
        prefillParentFetchRef.current.abort();
      }

      const filters = [
        { value: value?.parent, operation: ApiFilterOperation.EQ, field: 'id' },
      ];
      prefillParentFetchRef.current = callApi(parentUrl, filters);

      const json: ListSource<RuianObjectAutocomplete> = await prefillParentFetchRef.current.json();
      const options = json.items;

      if (options.length === 1) {
        setFieldValue(`${addressFieldCtx.name}.${parentName}`, options[0]);
      }
    }
  });

  const isDisabled = useMemo(
    () => addressFieldCtx.disabled || disabled(address) || emptySource,
    [address, addressFieldCtx.disabled, disabled, emptySource]
  );

  /**
   * Effect to update the source filter params and prefill the value if necessary.
   */
  useEffect(() => {
    if (
      (!detailCtx || detailCtx?.mode !== DetailMode.VIEW) &&
      !disablePrefill &&
      !addressFieldCtx.disabled &&
      !(!addressFieldCtx.isRuian && customName)
    ) {
      callPrefill();
    }
    source.setParams({
      filters: createFilter(address),
      sort: [{ field: 'name', order: 'ASC', type: 'FIELD' }],
    });
  }, [...filterDeps(address), isDisabled]);

  /**
   * Effect to prefill the parent each time the its value is changed.
   */
  useUpdateEffect(() => {
    if (value?.parent && parentUrl && !addressFieldCtx.disabled && editing) {
      callPrefillParent();
    }
  }, [value?.parent]);

  /**
   * Erase the value of all descendants.
   */
  const handleNotifyChange = useEventCallback(() => {
    unstable_batchedUpdates(() => {
      for (const descName of descendantNames) {
        setFieldValue(`${addressFieldCtx.name}.${descName}`, null);
      }
      notifyChange(address);
    });
  });

  const labelMapper = useEventCallback(
    (val: RuianObjectAutocomplete, type: 'FIELD' | 'OPTION') => {
      if (type === 'FIELD') {
        return val?.name ?? val.label ?? '';
      } else {
        return val?.label ?? '' ?? '';
      }
    }
  );

  if (!addressFieldCtx.isRuian && customName) {
    return (
      <FormTextField
        name={customName}
        label={<HelpLabel label={label} code={code} required={required} />}
        disabled={addressFieldCtx.disabled}
      />
    );
  } else {
    return (
      <FormAutocomplete<RuianObjectAutocomplete>
        name={name}
        source={source}
        before={before(address)}
        label={<HelpLabel label={label} code={code} required={required} />}
        notifyChange={handleNotifyChange}
        labelMapper={labelMapper}
        tooltipMapper={(val) => val.label}
        disabled={isDisabled}
      />
    );
  }
}
