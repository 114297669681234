import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { useValidationSchema } from './uform-schema';
import { UForm } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { UFormFields } from './uform-fields';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { useColumns } from './uform-columns';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { UFormActionbar } from './uform-actionbar';

export function UForms() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = usePermission<UForm>({
    options: {
      // NEW: [Permission.UForm.UFORM_CREATE],
      // EDIT: [Permission.UForm.UFORM_UPDATE],
      // REMOVE: [Permission.UForm.UFORM_DELETE],
      // CHECK: [Permission.UForm.UFORM_CREATE, Permission.UForm.UFORM_UPDATE],
      // SAVE: [Permission.UForm.UFORM_CREATE, Permission.UForm.UFORM_UPDATE],
      // CLOSE: [Permission.UForm.UFORM_CREATE, Permission.UForm.UFORM_UPDATE],
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useAuthoredEvidence<UForm>({
    identifier: PageKey.UFORM,
    apiProps: {
      url: EvidenceAPI.UFORM,
    },
    tableProps: {
      tableName: 'Jednoduché formuláře',
      reportTag: ExportTags.UFORM,
      defaultSorts: [],
      columns,
    },
    detailProps: {
      validationSchema,
      FieldsComponent: UFormFields,
      toolbarProps: {
        title: 'Jednoduchý formulář',
        ActionBar: UFormActionbar,
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
