/* eslint-disable prettier/prettier */
import React, { useContext, MouseEvent } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import {
  Menubar,
  AdminProvider,
  UserContext,
  useFirstRender,
  NavigationContext,
  UserSettingsProvider,
  WebsocketProvider,
  ReportingProvider,
  ExportProvider,
  EvidenceDefaultsProvider,
} from '@eas/common-web';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles, createStyles, darken, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import logo from './components/menu/icon.svg';
import { ProtectedRoute } from './components/protected-page/protected-route';
import { ConsentSnackbarProvider } from './components/consent-snackbar/consent-snackbar-provider';
import { SubjectsDataProvider } from './modules/subject/subjects-context';
import { MenuBarItemsChanger } from './components/menu/menu-changer';
import * as R from './modules';
import { Permission, EvidenceUrl, EvidenceAPI } from './enums';
import { useIntl } from 'react-intl';
import { useMenuBarUserActions } from './components/menu/items/menu-bar-user-actions';
import { MenubarActions } from './composite/menubar-actions/menubar-actions';
import { IdentityProviderAdd } from './components/identity-provider/identity-provider-add';
import { useExportTags } from './modules/dict-export/export-api';
import { BoilerPermissionContext } from './components/boiler-permission/boiler-permission-context';

export const getEnvironmentColor = (theme: Theme, dark = false) => {
  const devColor = '#e84393';
  const testColor = '#dfa400';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  switch (ENVIRONMENT) {
    case 'dev':
      return dark ? darken(devColor, 0.2) : devColor;
    case 'test':
      return dark ? darken(testColor, 0.2) : testColor;
    default:
      return dark ? theme.palette.primary.dark : theme.palette.primary.main;
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      fontWeight: 400,
      fontSize: 12,
      color: 'white',
      marginLeft: 10,
    },
    subMenuItem: {
      margin: 0,
    },
    subMenuItemIcon: {
      minWidth: 25
    },
    icon: {
      color: 'white',
      width: '20px',
      height: '20px',
      padding: 0,
      margin: 0,
    },
    button: {
      height: 33,
      color: 'white',
      alignItems: 'flex-start',
      fontSize: '12px',
      minWidth: 52,
      backgroundColor: getEnvironmentColor(theme),
      '&:hover': {
        backgroundColor: getEnvironmentColor(theme, true),
      },
    },
    menuBarWrapper: {
      top: '0',
      width: '100%',
      display: 'flex',
      zIndex: 1100,
      alignItems: 'flex-end',
      borderBottom: '1px solid #e7e7e7',
      backgroundColor: getEnvironmentColor(theme),
    },
    iconlink: {
      '&:hover': {
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: 'rgb(0, 0, 0, 0.1)',
      }
    },
    menubarUserButton: {
      fontSize: 12,
      color: 'white',
      textTransform: 'initial',
      backgroundColor: getEnvironmentColor(theme),
      '&:hover': {
        backgroundColor: getEnvironmentColor(theme, true),
      }
    }
  })
);

export function ProtectedApp() {
  const classes = useStyles();

  const { user, hasPermission } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { userBtnActions } = useMenuBarUserActions();
  const { hasBoilerPermissions } = useContext(BoilerPermissionContext);

  const { pathname } = useLocation();

  useFirstRender(() => {
    // redirect to login screen
    if (!user) {
      if (pathname) {
        window.sessionStorage.setItem('redirectTo', pathname);
      }
      navigate('/prihlaseni', true);
    } else {
      const redirectTo = window.sessionStorage.getItem('redirectTo');
      window.sessionStorage.removeItem('redirectTo');
      // redirect to 2fa screen
      if (
        hasPermission(Permission.Default.TWO_FACTOR_PRE_AUTH) &&
        pathname !== '/crzp/dvoufaktorove-overeni'
      ) {
        navigate('/crzp/dvoufaktorove-overeni');
      } else {
        if (redirectTo) {
          navigate(redirectTo, true);
        }
      }
    }
  });

  const intl = useIntl();

  const exportTags = useExportTags();

  return (
    <EvidenceDefaultsProvider appName="CRŽP" panelUnmountOnExit={true} sortMissingValues="FIRST_ON_ASC" entityLocking={{enabled: true}}>
      <WebsocketProvider
        wsUrl="/api/crzp/stomp"
        destinations={['/user/queue/session', '/user/queue/notification']}
        debug={false}
      >
        <UserSettingsProvider url="/api/crzp/user-settings">
          <Menubar
            userBtnActions={userBtnActions}
            displayLogoutBtn={false}
            logo={
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  window.location.href = 'https://crzp.mzp.cz/portal/'
                }}
                href="https://crzp.mzp.cz/portal/"
                title="Portál CRŽP"
              >
                <img src={logo} alt="logo" className={classes.logo} />
              </a>
            }
            title={
              <div className={classes.titleWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  // href="https://crzp.mzp.cz/portal/"
                  title="Hlavní stránka aplikace"
                  href="/crzp"
                  onClick={(e: MouseEvent<HTMLAnchorElement> ) => {
                    e.preventDefault();
                    navigate('/crzp')
                  }}
                  disableElevation
                  className={classes.button}
                >
                  <HomeIcon className={classes.icon} />
                </Button>
                <Typography className={classes.title} component="div">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'CRZP_APP_NAME',
                        defaultMessage: 'CENTRÁLNÍ REGISTR životního prostředí',
                      }),
                    }}
                  />
                </Typography>
              </div>
            }
            items={[]}
            beforeUserBtn={<MenubarActions />}
            classOverrides={{
              userButton: classes.menubarUserButton,
              subMenuItem: classes.subMenuItem,
              subMenuItemIcon: classes.subMenuItemIcon,
              iconlink: classes.iconlink,
            //   menuContainer: classes.menuContainer,
              menuBarWrapper: classes.menuBarWrapper,
              // userButton: classes.menubarUserButton,
            //   userMenu: classes.menubarUserMenu,
            //   subMenu: classes.menubarSubmenu,
            //   menuBarInnerWrapper: classes.menuBarInnerWrapper,
            //   menu: classes.menu,
            //   menuItem: classes.menuItem,
            //   subMenuTopLevel: classes.subMenuTopLevel,
            //   subMenuItem: classes.subMenuItem,
            //   subMenuItemIcon: classes.subMenuItemIcon,
            }}
          >
            <ExportProvider url={EvidenceAPI.EXPORT} tags={exportTags} disableAsync>
              <ReportingProvider url={EvidenceAPI.REPORTING}>
                <ConsentSnackbarProvider>
                  <AdminProvider
                    permission={Permission.Default.ADMINISTRATOR}
                    prefix="/crzp"
                    reindexUrl="/api/crzp/admin/reindex"
                    soapMessagesUrl={EvidenceAPI.SOAP_MESSAGES}
                  />
                  <MenuBarItemsChanger />
                  <Switch>
                    <Route path={`${EvidenceUrl.OUTAGES_PUBLIC}/:id?`} component={R.OutagesPublic} />
                    <Route path={`${EvidenceUrl.PUBLIC_RECORDS}/:id?`} component={R.PublicRecords} />

                    <ProtectedRoute path={`${EvidenceUrl.ADMIN_SCHEDULE_JOBS}/:id?`} component={R.ScheduleJobs} permissions={[Permission.ScheduledTask.SCHEDULED_TASK_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.ADMIN_SCHEDULE_RUNS}/:id?`} component={R.ScheduleRuns} permissions={[Permission.ScheduledTask.SCHEDULED_TASK_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.ADMIN_ACTIONS}/:id?`} component={R.Actions} permissions={[Permission.Action.ACTIONS_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.AGENDAS}/:id?`} component={R.Agendas} permissions={[Permission.Agenda.AGENDA_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.ALOG}/:id?`} component={R.AuditLog} permissions={[Permission.Alog.ALOG_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_BOILER_BRANDS}/:id?`} component={R.DictionaryBoilerBrands} permissions={[Permission.Ozo.OZO_MODEL_LIST_ALL]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_BOILER_MODELS}/:id?`} component={R.DictionaryBoilerModels} permissions={[Permission.Ozo.OZO_BRAND_LIST_ALL]} />
                    <ProtectedRoute path={`${EvidenceUrl.BOILER_MANUFACTURER_NEW}`} component={R.BoilerManufacturerNew} permissions={[ Permission.Ozo.OZO_MANUFACTURER_UPDATE ]} />
                    <ProtectedRoute path={`${EvidenceUrl.BOILER_MANUFACTURERS}/:id?`} component={R.BoilerManufacturers} permissions={[ Permission.Ozo.OZO_MANUFACTURER_LIST_ALL, Permission.Ozo.OZO_MANUFACTURER_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.BOILER_PERMISSIONS_NEW}`} component={R.BoilerPermissionNew} permissions={[Permission.Ozo.OZO_PERMISSION_AGENDA_ASSIGN]} />
                    <ProtectedRoute path={`${EvidenceUrl.BOILER_PERMISSIONS}/:id?`} component={R.BoilerPermissions} permissions={[ Permission.Ozo.OZO_PERMISSION_LIST_ALL, Permission.Ozo.OZO_PERMISSION_LIST_OWN]} enableRedirect={hasBoilerPermissions}/>
                    <ProtectedRoute path={`${EvidenceUrl.DIRP_RECORDS}/:id?`} component={R.DirpRecords} permissions={[ Permission.Dirp.DIRP_LIST ]} />
                    <ProtectedRoute path={`${EvidenceUrl.EMPOWERMENTS}/:id?`} component={R.Empowerments} permissions={[ Permission.Empowerment.EMPOWERMENT_LIST_ALL, Permission.Empowerment.EMPOWERMENT_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.EMPOWERMENT_REQUESTS_NEW}`} component={R.EmpowermentRequestsNew} permissions={[ Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL, Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.EMPOWERMENT_REQUESTS}/:id?`} component={R.EmpowermentRequests} permissions={[ Permission.Empowerment.EMPOWERMENT_REQUEST_DETAIL_ALL, Permission.Empowerment.EMPOWERMENT_REQUEST_DETAIL_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_BALANCE_UNITS}/:id?`} component={R.DictionaryBalanceUnits} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_BOILER_TYPES}/:id?`} component={R.DictionaryBoilerTypes} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_COMPETENT_AUTHORITIES}/:id?`} component={R.DictionaryCompetentAuthorities} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES}/:id?`} component={R.DictionaryCompetentControlAuthorities} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_COUNTRIES}/:id?`} component={R.DictionaryCountries} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DECISION_TYPES}/:id?`} component={R.DictionaryDecisionTypes} permissions={[Permission.MuvDecisionType.MUV_DECISION_TYPE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DEPOSITS}/:id?`} component={R.DictionaryDeposits} permissions={[Permission.Deposit.DEPOSIT_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DIRP_AREAS}/:id?`} component={R.DictionaryDirpAreas} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DIRP_AUTHORS}/:id?`} component={R.DictionaryDirpAuthors} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DIRP_LAWS}/:id?`} component={R.DictionaryDirpLaws} permissions={[Permission.Dirp.DIRP_LAW_READ]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DIRP_LAW_PARAGRAPHS}/:id?`} component={R.DictionaryDirpLawParagraphs} permissions={[Permission.Dirp.DIRP_LAW_READ]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_DIRP_TYPES}/:id?`} component={R.DictionaryDirpTypes} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_EPRTR_ACTIVITIES}/:id?`} component={R.DictionaryEprtrActivities} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_INDICATORS}/:id?`} component={R.DictionaryIndicators} permissions={[Permission.MuvIndicator.MUV_INDICATOR_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_INFOS}/:id?`} component={R.DictInfos} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_IRZ_ACTIVITIES}/:id?`} component={R.DictionaryIrzActivities} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_IPPC_ACTIVITIES}/:id?`} component={R.DictionaryIppcActivities} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES}/:id?`} component={R.DictionaryHydrogeologicalZones} permissions={[Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_LEGAL_FORMS}/:id?`} component={R.DictionaryLegalForms} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_MINERAL_GROUPS}/:id?`} component={R.DictionaryMineralGroups} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_NACES}/:id?`} component={R.DictionaryNaceItems} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_CZ_NACES}/:id?`} component={R.DictionaryNaceCzItems} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_OVSS}/:id?`} component={R.DictionaryOvss} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_PROCESSING_PURPOSE}/:id?`} component={R.DictionaryProcessingPurposes} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS}/:id?`} component={R.DictionaryRiverBasinCzDistricts} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RIVER_BASIN_EU_DISTRICTS}/:id?`} component={R.DictionaryRiverBasinEuDistricts} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RIVER_SIDES}/:id?`} component={R.DictionaryRiverSides} permissions={[Permission.MuvRiverSide.MUV_RIVER_SIDE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_SAMPLE_TYPES}/:id?`} component={R.DictionarySampleTypes} permissions={[Permission.MuvSampleType.MUV_SAMPLE_TYPE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_SEWER_TYPES}/:id?`} component={R.DictionarySewerTypes} permissions={[Permission.MuvSewerType.MUV_SEWER_TYPE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS}/:id?`} component={R.DictionaryTerritorialTechnicalUnits} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_WATER_FLOWS}/:id?`} component={R.DictionaryWaterFlows} permissions={[Permission.MuvWaterFlow.MUV_WATER_FLOW_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_WATER_ORIGINS}/:id?`} component={R.DictionaryWaterOrigins} permissions={[Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS}/:id?`} component={R.DictionaryWaterTreatmentMethods} permissions={[Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_ADDRESS_PLACE}/:id?`} component={R.DictionaryRuianAddressPlace} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY}/:id?`} component={R.DictionaryRuianMunicipality} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY_PART}/:id?`} component={R.DictionaryRuianMunicipalityPart} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_DISTRICT}/:id?`} component={R.DictionaryRuianDistrict} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE}/:id?`} component={R.DictionaryRuianDistrictAdministrativePrague} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_CADASTRAL_TERRITORY}/:id?`} component={R.DictionaryRuianCadastralTerritory} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_REGION}/:id?`} component={R.DictionaryRuianRegions} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_STREET}/:id?`} component={R.DictionaryRuianStreet} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE}/:id?`} component={R.DictionaryRuianCityDistrictPrague} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT}/:id?`} component={R.DictionaryRuianCityDistrict} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY}/:id?`} component={R.DictionaryRuianExtendedCompetenceMunicipality} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY}/:id?`} component={R.DictionaryRuianCommissionedLocalAuthorityMunicipality} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.DICTIONARY_RUIAN_COHESION_REGION}/:id?`} component={R.DictionaryRuianCohesionRegion} permissions={[Permission.Dictionary.DICTIONARY_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.EXPORT_ACCESS_RULES}/:id?`} component={R.ExportAccessRules} permissions={[Permission.ExportAccessRule.EXPORT_ACCESS_RULE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.EXPORT_TEMPLATES}/:id?`} component={R.DictionaryExportTemplates} permissions={[Permission.ExportAccessRule.EXPORT_ACCESS_RULE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.EXPORT_REQUESTS}/:id?`} component={R.DictionaryExportRequests} permissions={[Permission.Export.EXPORT_REQUEST_LIST, Permission.Default.ADMINISTRATOR]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITIES}/:id?`} component={R.IrzFacilities} permissions={[ Permission.IrzFacility.IRZ_LIST_ALL, Permission.IrzFacility.IRZ_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_REQUEST_CHANGE}`} component={R.IrzFacilityRequestChange} permissions={[ Permission.IrzFacility.IRZ_REQUEST_UPDATE_SUBMIT, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_REQUEST_NEW}`} component={R.IrzFacilityRequestNew} permissions={[ Permission.IrzFacility.IRZ_REQUEST_CREATE_SUBMIT, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_REQUEST_OPERATOR_CHANGE}`} component={R.IrzFacilityRequestOperatorChange} permissions={[ Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_SUBMIT, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_MERGE}`} component={R.IrzFacilityMerge} permissions={[Permission.IrzFacility.IRZ_MERGE]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_SPLIT}`} component={R.IrzFacilitySplit} permissions={[Permission.IrzFacility.IRZ_SPLIT]} />
                    <ProtectedRoute path={`${EvidenceUrl.IRZ_FACILITY_REQUESTS}/:id?`} component={R.IrzFacilityRequests} permissions={[ Permission.IrzFacility.IRZ_REQUEST_LIST_ALL, Permission.IrzFacility.IRZ_REQUEST_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.ISPOP_DOCUMENTS}/:id?`} component={R.IspopDocuments} permissions={[ Permission.IspopDocument.ISPOP_DOCUMENT_LIST]}  />
                    <ProtectedRoute path={`${EvidenceUrl.MAILING}/:id?`} component={R.MailCampaigns} permissions={[Permission.Mailing.MAILING_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.NOTIFICATION_TEMPLATES}/:id?`} component={R.NotificationTemplates} permissions={[ Permission.Notification.NOTIFICATION_TEMPLATE_LIST, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.NOTIFICATIONS}/:id?`} component={R.Notifications} permissions={[ Permission.Notification.NOTIFICATION_LIST, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.NOTIFICATION_BATCHES}/:id?`} component={R.NotificationBatches} permissions={[ Permission.Notification.NOTIFICATION_BATCH_LIST, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVERVIEW_MAP}/:id?`} component={R.OverviewMap} permissions={[ Permission.MapOverview.MAP_OVERVIEW ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVM}/:id?`} component={R.OVMs} permissions={[ Permission.UForm.UFORM_LIST_ALL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVZ_FACILITIES}/:id?`} component={R.OvzFacilities} permissions={[ Permission.OvzFacility.OVZ_LIST_ALL, Permission.OvzFacility.OVZ_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVZ_FACILITY_NEW}`} component={R.OvzFacilityNew} permissions={[ Permission.OvzFacility.OVZ_CREATE_ALL, Permission.OvzFacility.OVZ_CREATE_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE}`} component={R.OvzFacilityRequestOperatorChange} permissions={[ Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OVZ_FACILITY_REQUESTS}/:id?`} component={R.OvzFacilityRequests} permissions={[ Permission.OvzFacility.OVZ_REQUEST_LIST_ALL, Permission.OvzFacility.OVZ_REQUEST_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.OUTAGES}/:id?`} component={R.Outages} permissions={[Permission.Outage.OUTAGE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.OZO_SUBJECTS}/:id?`} component={R.OzoSubjects} permissions={[Permission.Subject.SUBJECT_LIST_OZO]} />
                    <ProtectedRoute path={`${EvidenceUrl.PERSONAL_DATA}/:id?`} component={R.PersonalData} permissions={[Permission.PersonalData.PERSONAL_DATA_LIST_ALL, Permission.PersonalData.PERSONAL_DATA_LIST_OWN,]} />
                    <ProtectedRoute path={`${EvidenceUrl.PRODUCTION_SITES}/:id?`} component={R.ProductionSites} permissions={[Permission.ProductionSite.PRODUCTION_SITE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.PRODUCTION_SITE_MERGE}`} component={R.ProductionSiteMerge} permissions={[ Permission.ProductionSite.PRODUCTION_SITE_MERGE, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.PRODUCTION_SITE_SPLIT}`} component={R.ProductionSiteSplit} permissions={[ Permission.ProductionSite.PRODUCTION_SITE_SPLIT, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.PROTECTED_DEPOSIT_AREAS}/:id?`} component={R.ProtectedDepositAreas} permissions={[Permission.Chlu.CHLU_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.PUBLIC_LISTS}/:id?`} component={R.PublicLists} permissions={[Permission.PublicList.PUBLIC_LIST_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.PUBLIC_LIST_PUBLISHINGS}/:id?`} component={R.PublicListPublishings} permissions={[Permission.PublicList.PUBLIC_LIST_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.REPORTING_DATA}/:code?`} component={R.Reporting} permissions={[Permission.Reporting.REPORTING_DETAIL, Permission.Reporting.REPORTING_DETAIL_OWN]} />
                    <ProtectedRoute path={`${EvidenceUrl.REPORTING_ACCESS_RULES}/:id?`} component={R.ReportAccessRules} permissions={[Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.ROLES}/:id?`} component={R.Roles} permissions={[Permission.Role.ROLE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.ROLE_INSTANCES}/:id?`} component={R.RoleInstances} permissions={[Permission.Role.ROLE_INSTANCE_LIST, Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE, Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE, Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE, Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE, Permission.Role.ROLE_INSTANCE_LIST_SELECTED ]} />
                    <ProtectedRoute path={`${EvidenceUrl.ROLES_REQUESTS}/:id?`} component={R.RoleRequests} permissions={[Permission.RoleRequest.ROLE_REQUEST_LIST_OWN, Permission.RoleRequest.ROLE_REQUEST_LIST_ALL ]} enableRedirect={user?.subjectPermissions && Object.values(user?.subjectPermissions)?.length > 0} />
                    <ProtectedRoute path={`${EvidenceUrl.ROLE_REQUESTS_NEW}`} component={R.RoleRequestsNew} permissions={[]} enableRedirect={user?.subjectPermissions && Object.values(user?.subjectPermissions)?.length > 0} />
                    <Route path={EvidenceUrl.SUBJECTS}>
                      <SubjectsDataProvider>
                        <ProtectedRoute path={`${EvidenceUrl.SUBJECTS}/:id?`} component={R.Subjects} permissions={[Permission.Subject.SUBJECT_LIST_ALL, Permission.Subject.SUBJECT_LIST_OWN, Permission.Subject.SUBJECT_LIST_WITHOUT_PERSONAL ]} />
                      </SubjectsDataProvider>
                    </Route>
                    <ProtectedRoute path={`${EvidenceUrl.SEQUENCES}/:id?`} component={R.DictionarySequences} permissions={[]} />
                    <ProtectedRoute path={`${EvidenceUrl.SOAP_MESSAGE_LOGS}/:id?`} component={R.SoapMessageLogs} permissions={[Permission.Default.ADMINISTRATOR]} />
                    <ProtectedRoute path={`${EvidenceUrl.SOAP_MESSAGES}/:id?`} component={R.SoapMessages} permissions={[Permission.SOAPMessage.WS_CLIENT_MESSAGE_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.SOAP_EXECUTIONS}/:id?`} component={R.SoapExecutionLogs} permissions={[Permission.WSExecution.WS_EXECUTION_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.SUBJECT_REQUESTS_NEW}`} component={R.SubjectRequestsNew} permissions={[Permission.Default.LOGGED_USER]} />
                    <ProtectedRoute path={`${EvidenceUrl.SUBJECT_REQUESTS}/:id?`} component={R.SubjectRequests} permissions={[Permission.Default.LOGGED_USER]} />
                    <ProtectedRoute path={`${EvidenceUrl.SYSTEMS}/:id?`} component={R.Systems} permissions={[Permission.System.SYSTEM_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.THIRD_PARTY_SYSTEMS}/:id?`} component={R.ThirdPartySystems} permissions={[ Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_LIST, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.TRANSLATIONS}/:id?`} component={R.Translations} permissions={[Permission.Help.HELP_LIST]} />
                    <ProtectedRoute path={`${EvidenceUrl.UNCONFIRMED_USER_REGISTRATIONS}/:id?`} component={R.UnconfirmedUserRegistrations} permissions={[ Permission.User.USER_REGISTRATION_LIST ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_GOV_ACT}/:id?`} component={R.UFormGovActs} permissions={[ Permission.UForm.UFORM_LIST_ALL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_GOV_AGENDA}/:id?`} component={R.UFormGovAgendas} permissions={[ Permission.UForm.UFORM_LIST_ALL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_GOV_SERVICE}/:id?`} component={R.UFormGovServices} permissions={[ Permission.UForm.UFORM_LIST_ALL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_NEW}/:id?`} component={R.UFormNew} permissions={[ Permission.UForm.UFORM_CREATE ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_RESULT}`} component={R.UFormResult} permissions={[ Permission.UForm.UFORM_CREATE ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM_MINE}/:id?`} component={R.UFormsMine} permissions={[ Permission.UForm.UFORM_LIST_OWN ]} />
                    <ProtectedRoute path={`${EvidenceUrl.UFORM}/:id?`} component={R.UForms} permissions={[ Permission.UForm.UFORM_LIST_ALL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.USERS}/:id?`} component={R.Users} permissions={[ Permission.User.USER_LIST_ALL, Permission.User.USER_LIST_WITHOUT_PERSONAL ]} />
                    <ProtectedRoute path={`${EvidenceUrl.WATER_MANAGEMENT_DECISIONS}/:id?`} component={R.WaterManagementDecisions} permissions={[ Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_ALL, Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.WATER_MANAGEMENT_DECISION_PARTS}/:id?`} component={R.WaterManagementDecisionParts} permissions={[ Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_LIST_ALL, Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.WATER_USAGE_PLACES}/:id?`} component={R.WaterUsagePlaces} permissions={[ Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_ALL, Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_OWN, ]} />
                    <ProtectedRoute path={`${EvidenceUrl.WEB_SERVICES}/:id?`} component={R.WebServices} permissions={[Permission.WebService.WS_LIST]} />

                    <Route path="/crzp/profil" component={R.ProfilePage} />
                    <Route path="/crzp/pridani-idp" component={IdentityProviderAdd} />
                    <Route path="/crzp/dvoufaktorove-overeni" component={R.TwoFactorCode} />
                    <Route path="/crzp" exact component={R.Dashboard} />
                    <Route path="/crzp/na-tomhle-jeste-pracujeme" component={R.UnderConstruction} />
                  </Switch>
                </ConsentSnackbarProvider>
              </ReportingProvider>
            </ExportProvider>
          </Menubar>
        </UserSettingsProvider>
      </WebsocketProvider>
    </EvidenceDefaultsProvider>
  );
}
