import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { useValidationSchema } from './uform-gov-agenda-schema';
import { UFormGovAgenda } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { UFormGovAgendaFields } from './uform-gov-agenda-fields';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function UFormGovAgendas() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<UFormGovAgenda>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDictionaryEvidence<UFormGovAgenda>({
    identifier: PageKey.UFORM_GOV_AGENDA,
    apiProps: {
      url: EvidenceAPI.UFORM_GOV_AGENDA,
    },
    tableProps: {
      tableName: 'Agendy',
      reportTag: ExportTags.UFORM_GOV_AGENDA,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'AGENDA',
      validationSchema,
      GeneralFieldsComponent: UFormGovAgendaFields,
      toolbarProps: {
        title: 'Agenda',
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
