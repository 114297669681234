import { useMemo } from 'react';
import { StepSummaryLabel } from './step-summary/step-summary-label';
import { StepSummaryContent } from './step-summary/step-summary-content';
import { StepSummaryActions } from './step-summary/step-summary-actions';
import { StepAgendaLabel } from './step-agenda/step-agenda-label';
import { StepAgendaContent } from './step-agenda/step-agenda-content';
import { stepAgendaSchema } from './step-agenda/step-agenda-schema';
import { StepOriginatorLabel } from './step-originator/step-originator-label';
import { StepOriginatorContent } from './step-originator/step-originator-content';
import { stepOriginatorSchema } from './step-originator/step-originator-schema';
import { ProfileForm } from '../profile/profile-types';
import { StepSubjectLabel } from './step-subject/step-subject-label';
import { StepSubjectContent } from './step-subject/step-subject-content';
import { stepSubjectSchema } from './step-subject/step-subject-schema';
import { StepFinishLabel } from './step-finish/step-finish-label';
import { StepFinishContent } from './step-finish/step-finish-content';
import { EmptyComponent } from '@eas/common-web';

export function useUFormRequests(profile: ProfileForm) {
  console.log(profile);
  const steps = useMemo(
    () => [
      {
        Label: StepAgendaLabel,
        Content: StepAgendaContent,
        validationSchema: stepAgendaSchema,
      },
      {
        Label: StepOriginatorLabel,
        Content: StepOriginatorContent,
        validationSchema: stepOriginatorSchema,
        initialValues: {
          actor: {
            firstName: profile?.firstName?.value || '',
            lastName: profile?.lastName?.value || '',
            idNumber: profile?.identityDocuments?.[0]?.idNumber?.value || '',
            databox: profile?.isdsIds?.[0] || '',
            address: profile?.residence || {},
          },
        },
      },
      {
        Label: StepSubjectLabel,
        Content: StepSubjectContent,
        validationSchema: stepSubjectSchema,
      },
      {
        Label: StepSummaryLabel,
        Content: StepSummaryContent,
        Actions: StepSummaryActions,
      },
      {
        Label: StepFinishLabel,
        Content: StepFinishContent,
        Actions: EmptyComponent,
      },
    ],
    [profile]
  );

  return { steps };
}
