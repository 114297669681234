import { abortableFetch, DetailHandle, DetailContext } from '@eas/common-web';
import { EvidenceAPI } from '../../../enums';
import { useContext } from 'react';
import { OVM } from '../../../models';

export function useEditCustomIsdsDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<OVM>>(DetailContext);

  const callApi = (
    id: string,
    values: { customIsds: { isds: string; comment: string }[] }
  ) => {
    return abortableFetch(`${EvidenceAPI.OVM}/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        customIsds: values.customIsds,
      }),
    });
  };

  const canEditCustomIsds = true;

  return { callApi, canEditCustomIsds };
}
