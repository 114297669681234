import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { OVM } from '../../models';
import { useValidationSchema } from './ovm-schema';
import { EvidenceAPI, PageKey, EntityName } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { OVMFields } from './ovm-fields';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { OVMActActionbar } from './ovm-actionbar';

export function OVMs() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<OVM>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDictionaryEvidence<OVM>({
    identifier: PageKey.OVM,
    apiProps: {
      url: EvidenceAPI.OVM,
    },
    tableProps: {
      tableName: 'Orgány veřejné moci',
      reportTag: ExportTags.OVM,
      defaultSorts: [],
    },
    detailProps: {
      entityName: EntityName.OVM,
      codePrefix: 'OVM',
      validationSchema,
      GeneralFieldsComponent: OVMFields,
      toolbarProps: {
        title: 'Orgán veřejné moci',
        showBtn: permissions,
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.OVM}
              showBtn={() => false}
              CustomActions={OVMActActionbar}
            />
          );
        },
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
