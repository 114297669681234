import React from 'react';
import { StepOriginatorForm } from './step-originator-types';

export function StepOriginatorLabel({
  stepValues,
}: {
  stepValues?: StepOriginatorForm;
}) {
  return (
    <>
      Osoba činící úkon:{' '}
      {
        <b>
          {stepValues?.actor?.firstName} {stepValues?.actor?.lastName}
        </b>
      }
    </>
  );
}
