/**
 * Page keys
 */
export enum PageKey {
  // public
  DATABOX_VERIFICATION = 'DATABOX_VERIFICATION',
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  REGISTRATION = 'REGISTRATION',
  REGISTRATION_FORM = 'REGISTRATION_FORM',
  REGISTRATION_EXTERNAL = 'REGISTRATION_EXTERNAL',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_REQUEST_SENT = 'PASSWORD_RESET_REQUEST_SENT',
  PASSWORD_RESET_COMPLETED = 'PASSWORD_RESET_COMPLETED',

  // protected
  AGENDAS = 'AGENDAS',
  BOILER_MANUFACTURERS = 'BOILER_MANUFACTURERS',
  BOILER_PERMISSIONS = 'BOILER_PERMISSIONS',
  DICTIONARY_BALANCE_UNITS = 'DICTIONARY_BALANCE_UNITS',
  DICTIONARY_BOILER_BRANDS = 'DICTIONARY_BOILER_BRANDS',
  DICTIONARY_BOILER_MODELS = 'DICTIONARY_BOILER_MODELS',
  DICTIONARY_BOILER_TYPES = 'DICTIONARY_BOILER_TYPES',
  DICTIONARY_COUNTRIES = 'DICTIONARY_COUNTRIES',
  DICTIONARY_COMPETENT_AUTHORITIES = 'DICTIONARY_COMPETENT_AUTHORITIES',
  DICTIONARY_COMPETENT_CONTROL_AUTHORITIES = 'DICTIONARY_COMPETENT_CONTROL_AUTHORITIES',
  DICTIONARY_CZ_NACES = 'DICTIONARY_CZ_NACES',
  DICTIONARY_DECISION_TYPES = 'DICTIONARY_DECISION_TYPES',
  DICTIONARY_DEPOSITS = 'DICTIONARY_DEPOSITS',
  DICTIONARY_DIRP_AREAS = 'DICTIONARY_DIRP_AREAS',
  DICTIONARY_DIRP_AUTHORS = 'DICTIONARY_DIRP_AUTHORS',
  DICTIONARY_DIRP_LAWS = 'DICTIONARY_DIRP_LAWS',
  DICTIONARY_DIRP_LAW_PARAGRAPHS = 'DICTIONARY_DIRP_LAW_PARAGRAPHS',
  DICTIONARY_DIRP_TYPES = 'DICTIONARY_DIRP_TYPES',
  DICTIONARY_EPRTR_ACTIVITIES = 'DICTIONARY_EPRTR_ACTIVITIES',
  DICTIONARY_INDICATORS = 'DICTIONARY_INDICATORS',
  DICTIONARY_INFOS = 'DICTIONARY_INFOS',
  DICTIONARY_IPPC_ACTIVITIES = 'DICTIONARY_IPPC_ACTIVITIES',
  DICTIONARY_IRZ_ACTIVITIES = 'DICTIONARY_IRZ_ACTIVITIES',
  DICTIONARY_HYDROGEOLOGICAL_ZONES = 'DICTIONARY_HYDROGEOLOGICAL_ZONES',
  DICTIONARY_LEGAL_FORMS = 'DICTIONARY_LEGAL_FORM',
  DICTIONARY_MINERAL_GROUPS = 'DICTIONARY_MINERAL_GROUPS',
  DICTIONARY_NACES = 'DICTIONARY_NACES',
  DICTIONARY_OVSS = 'DICTIONARY_OVSS',
  DICTIONARY_PROCESSING_PURPOSE = 'DICTIONARY_PROCESSING_PURPOSE',
  DICTIONARY_RIVER_BASIN_CZ_DISTRICTS = 'DICTIONARY_RIVER_BASIN_CZ_DISTRICTS',
  DICTIONARY_RIVER_BASIN_EU_DISTRICTS = 'DICTIONARY_RIVER_BASIN_EU_DISTRICTS',
  DICTIONARY_RIVER_SIDES = 'DICTIONARY_RIVER_SIDES',
  DICTIONARY_SAMPLE_TYPES = 'DICTIONARY_SAMPLE_TYPES',
  DICTIONARY_SEQUENCES = 'DICTIONARY_SEQUENCES',
  DICTIONARY_SEWER_TYPES = 'DICTIONARY_SEWER_TYPES',
  DICTIONARY_TERRITORIAL_TECHNICAL_UNITS = 'DICTIONARY_TERRITORIAL_TECHNICAL_UNITS',
  DICTIONARY_WATER_FLOWS = 'DICTIONARY_WATER_FLOWS',
  DICTIONARY_WATER_ORIGINS = 'DICTIONARY_WATER_ORIGINS',
  DICTIONARY_WATER_TREATMENT_METHODS = 'DICTIONARY_WATER_TREATMENT_METHODS',
  UFORM_GOV_ACT = 'UFORM_GOV_ACT',
  UFORM_GOV_AGENDA = 'UFORM_GOV_AGENDA',
  UFORM_GOV_SERVICE = 'UFORM_GOV_SERVICE',
  UFORM = 'UFORM',
  DICTIONARY_RUIAN_REGIONS = 'DICTIONARY_RUIAN_REGIONS',
  DICTIONARY_RUIAN_CADASTRAL_TERRITORY = 'DICTIONARY_RUIAN_CADASTRAL_TERRITORY',
  DICTIONARY_RUIAN_DISTRICT = 'DICTIONARY_RUIAN_DISTRICT',
  DICTIONARY_RUIAN_ADDRESS_PLACE = 'DICTIONARY_RUIAN_ADDRESS_PLACE',
  DICTIONARY_RUIAN_MUNICIPALITY = 'DICTIONARY_RUIAN_MUNICIPALITY',
  DICTIONARY_RUIAN_STREET = 'DICTIONARY_RUIAN_STREET',
  DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE = 'DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE',
  DICTIONARY_RUIAN_CITY_DISTRICT = 'DICTIONARY_RUIAN_CITY_DISTRICT',
  DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE = 'DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE',
  DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY = 'DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY',
  DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY = 'DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY',
  DICTIONARY_RUIAN_COHESION_REGION = 'DICTIONARY_RUIAN_COHESION_REGION',
  DICTIONARY_RUIAN_MUNICIPALITY_PART = 'DICTIONARY_RUIAN_MUNICIPALITY_PART',
  DIRP_RECORDS = 'DIRP_RECORDS',
  EMPOWERMENTS = 'EMPOWERMENTS',
  EMPOWERMENT_REQUESTS = 'EMPOWERMENT_REQUESTS',
  EXPORT_ACCESS_RULES = 'EXPORT_ACCESS_RULES',
  IRZ_FACILITIES = 'IRZ_FACILITIES',
  IRZ_FACILITY_REQUESTS = 'IRZ_FACILITY_REQUESTS',
  ISPOP_DOCUMENTS = 'ISPOP_DOCUMENTS',
  MAILING = 'MAILING',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATION_BATCHES = 'NOTIFICATION_BATCHES',
  NOTIFICATION_TEMPLATES = 'NOTIFICATION_TEMPLATES',
  OUTAGES = 'OUTAGES',
  OUTAGES_PUBLIC = 'OUTAGES_PUBLIC',
  OVM = 'OVM',
  OVZ_FACILITIES = 'OVZ_FACILITIES',
  OVZ_FACILITY_REQUESTS = 'OVZ_FACILITY_REQUESTS',
  PRODUCTION_SITES = 'PRODUCTION_SITES',
  PROTECTED_DEPOSIT_AREAS = 'PROTECTED_DEPOSIT_AREAS',
  PUBLIC_LISTS = 'PUBLIC_LISTS',
  PUBLIC_LIST_PUBLISHINGS = 'PUBLIC_LIST_PUBLISHINGS',
  PUBLIC_RECORDS = 'PUBLIC_RECORDS',
  REPORTING_ACCESS_RULES = 'REPORTING_ACCESS_RULES',
  ROLES = 'ROLES',
  ROLE_INSTANCES = 'ROLE_INSTANCES',
  ROLE_REQUESTS = 'ROLE_REQUESTS',
  SOAP_EXECUTION_LOGS = 'SOAP_EXECUTION_LOGS',
  SOAP_MESSAGE_LOGS = 'SOAP_MESSAGE_LOGS',
  SCHEDULE_JOBS = 'SCHEDULE_JOBS',
  SCHEDULE_RUNS = 'SCHEDULE_RUNS',
  SUBJECTS = 'SUBJECTS',
  SUBJECT_REQUESTS = 'SUBJECT_REQUESTS',
  SYSTEMS = 'SYSTEMS',
  THIRD_PARTY_SYSTEMS = 'THIRD_PARTY_SYSTEMS',
  UNCONFIRMED_USER_REGISTRATIONS = 'UNCONFIRMED_USER_REGISTRATIONS',
  USERS = 'USERS',
  WATER_MANAGEMENT_DECISIONS = 'WATER_MANAGEMENT_DECISIONS',
  WATER_MANAGEMENT_DECISION_PARTS = 'WATER_MANAGEMENT_DECISION_PARTS',
  WATER_USAGE_PLACES = 'WATER_USAGE_PLACES',
  WEB_SERVICES = 'WEB_SERVICES',
}
