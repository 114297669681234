import React, { useMemo } from 'react';
import {
  FormInlineTableField,
  TableFieldColumn,
  InlineTableFieldCells,
  FormFieldContext,
} from '@eas/common-web';
import {
  IdentityDocument,
  DictionaryAutocomplete,
  DocumentType,
} from '../../../../models';
import { useDocumentTypes } from '../../subjects-api';
import { HelpLabel } from '../../../../components/help/help-label';
import { identityCardMaxLength } from '../subjects-utils';

export function useDocumentsColumns(
  collectionDatakey: string,
  disabled?: boolean
): TableFieldColumn<IdentityDocument>[] {
  const TypeCell = InlineTableFieldCells.useInlineSelectCellFactory<
    IdentityDocument,
    DictionaryAutocomplete
  >({
    dataHook: useDocumentTypes,
    collectionDatakey,
    valueIsId: true,
    disabled: !!disabled,
  });

  const IdNumberCell = InlineTableFieldCells.useInlineTextFieldFactory<
    IdentityDocument
  >({
    collectionDatakey,
    isSubkey: true,
  });

  const MaxLengthIdNumberCell = InlineTableFieldCells.useInlineTextFieldFactory<
    IdentityDocument
  >({
    collectionDatakey,
    isSubkey: true,
    inputProps: {
      maxLength: identityCardMaxLength,
    },
  });

  const DateCell = InlineTableFieldCells.useInlineDateFieldFactory<
    IdentityDocument
  >({
    collectionDatakey,
    isSubkey: true,
    representation: 'local-date-time',
  });

  return useMemo(
    () => [
      {
        name: 'Typ',
        datakey: 'type',
        width: 200,
        CellComponent: TypeCell,
      },
      {
        name: 'Číslo dokladu',
        datakey: 'idNumber.value',
        width: 200,
        CellComponent: function Cell(props) {
          return props.rowValue.type !== DocumentType.PASSPORT ? (
            <MaxLengthIdNumberCell {...props} />
          ) : (
            <IdNumberCell {...props} />
          );
        },
      },
      {
        name: 'Platnost od',
        datakey: 'validFrom',
        width: 200,
        CellComponent: DateCell,
      },
      {
        name: 'Platnost do',
        datakey: 'validTo',
        width: 200,
        CellComponent: DateCell,
      },
    ],
    [TypeCell, IdNumberCell, MaxLengthIdNumberCell]
  );
}

export function NaturalPersonDocuments({
  prefix,
  codePrefix = 'SUBJECT',
  disabled,
  required = true,
}: {
  prefix?: string;
  codePrefix?: string;
  disabled?: boolean;
  required?: boolean;
}) {
  const names = {
    identityDocuments: prefix
      ? `${prefix}.identityDocuments`
      : 'identityDocuments',
  };

  const columns = useDocumentsColumns(names.identityDocuments, disabled);

  return (
    <FormFieldContext.Provider value={{ disabled }}>
      <FormInlineTableField
        name={names.identityDocuments}
        label={
          <HelpLabel
            label="Doklady"
            code={`${codePrefix}_IDENTITY_DOCUMENTS`}
            required={required}
          /> // Povinné pole
        }
        columns={columns}
        disabled={disabled}
      />
    </FormFieldContext.Provider>
  );
}
