import * as Yup from 'yup';
import { StepAgendaForm } from './step-agenda-types';
import { UFormGovAct, OVM } from '../../../models';

const isdsRegex = /^[a-np-zA-NP-Z2-9]{7}$/;

export const stepAgendaSchema = () =>
  Yup.object<StepAgendaForm>().shape({
    agenda: Yup.string().nullable().required('Povinné pole'),
    service: Yup.string().nullable().required('Povinné pole'),
    act: Yup.object<UFormGovAct>().nullable().required('Povinné pole'),
    recipient: Yup.object({
      authority: Yup.object<OVM>().nullable().required('Povinné pole'),
      databox: Yup.string()
        .nullable()
        .when('isCustomDatabox', {
          is: (val) => !val,
          then: Yup.string().required('Povinné pole'),
          otherwise: Yup.string().nullable(),
        }),
      isCustomDatabox: Yup.boolean().nullable(),
      department: Yup.string().nullable(),
      customDatabox: Yup.string()
        .nullable()
        .when('isCustomDatabox', {
          is: (val) => !!val,
          then: Yup.string()
            .matches(
              isdsRegex,
              'Pole musí obsahovat právě 7 znaků, pouze písmena a číslice (bez o, l, 0, 1)'
            )
            .required('Povinné pole'),
          otherwise: Yup.string().nullable(),
        }),
    }).required('Povinné pole'),
  });
