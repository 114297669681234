import React, { useContext } from 'react';
import { Evidence2, ApiFilterOperation, UserContext } from '@eas/common-web';
import { UForm } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { UFormFields } from '../uform/uform-fields';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { useColumns } from '../uform/uform-columns';
import { useValidationSchema } from '../uform/uform-schema';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { UFormActionbar } from '../uform/uform-actionbar';

export function UFormsMine() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const { user } = useContext(UserContext);

  const permissions = usePermission<UForm>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useAuthoredEvidence<UForm>({
    identifier: PageKey.UFORM,
    apiProps: {
      url: EvidenceAPI.UFORM,
    },
    tableProps: {
      tableName: 'Moje jednoduché formuláře',
      reportTag: ExportTags.UFORM,
      defaultSorts: [],
      columns,
      defaultPreFilters: [
        {
          field: 'createdBy.id',
          operation: ApiFilterOperation.EQ,
          value: user?.id,
        },
      ],
    },
    detailProps: {
      validationSchema,
      FieldsComponent: UFormFields,
      toolbarProps: {
        title: 'Jednoduchý formulář',
        ActionBar: UFormActionbar,
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
